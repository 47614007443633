<template>
	<AppLink
		v-if="Boolean(to || href)"
		class="baseButton"
		:class="[
			{
				disabled,
				outline,
				dense,
				text,
			},
			colorClass,
			roundedClass,
			textAlignmentClass,
		]"
		v-bind="appLinkProps"
		button
		inherit-color
	>
		<slot name="prepend" />
		<span class="grow"><slot name="default" /></span>
		<slot name="append" />
	</AppLink>
	<button
		v-else
		:type="type"
		class="baseButton"
		:class="[
			{
				disabled,
				outline,
				dense,
				text,
			},
			colorClass,
			roundedClass,
			textAlignmentClass,
		]"
		v-bind="{ disabled, to, href, type }"
		:aria-label="ariaLabel"
		:aria-live="ariaLive"
		@click="$emit('click', $event)"
		@keydown="$emit('keydown', $event)"
	>
		<slot name="prepend" />
		<span class="grow"><slot name="default" /></span>
		<slot name="append" />
	</button>
</template>

<script setup>
import { computed } from 'vue';
import AppLink from '@/components/ui/AppLink.vue';
import { getColorClass, getRoundedClass, getTextAlignmentClass } from '@/utils/styles';

const props = defineProps({
	disabled: { type: Boolean, default: false },
	outline: { type: Boolean, default: false },
	dense: { type: Boolean, default: false },
	text: { type: Boolean, default: false },
	type: { type: String, default: 'button' },
	textAlignment: { type: String, default: 'center' },

	to: { type: [String, Object], required: false },
	href: { type: String, default: null },

	color: { type: String, default: 'primary' },
	rounded: { type: [String, Boolean], default: 'full' },
	inheritColor: { type: Boolean, default: false },

	ariaLabel: { type: String, required: false },
	ariaLive: { type: String, default: 'polite', required: false },
	external: {
		type: Boolean,
		validator(value) {
			console.error(value);
			return !value;
		},
		default: null,
	},
});

const roundedClass = computed(() => getRoundedClass(props.rounded));
const colorClass = computed(() => getColorClass(props.color));
const textAlignmentClass = computed(() => getTextAlignmentClass(props.textAlignment));

const appLinkProps = computed(() => {
	const final = {
		color: props.color,
		disabled: props.disabled,
		ariaLabel: props.ariaLabel,
		inheritColor: props.inheritColor,
	};
	if (props.href) {
		final.href = props.href;

		// return early; we don't need the "to" prop at all
		return final;
	}
	if (props.to) {
		if (typeof props.to === 'string') {
			final.href = props.to;
		} else {
			final.to = props.to;
		}
	}
	return final;
});
</script>

<style scoped lang="scss">
.disabledBase {
	@apply cursor-not-allowed bg-gray-300 text-gray-800 hover:bg-gray-300 hover:text-black dark:bg-gray-900/40 dark:text-gray-200;
}
.baseButton,
a.baseButton {
	@apply inline-flex h-min items-center justify-center gap-2 outline-none ring-0 sm:whitespace-nowrap;
	@apply text-sm font-bold uppercase tracking-wider text-white no-underline transition;
	@apply hover:outline-none focus:outline-none focus:ring focus:ring-offset-2 dark:ring-offset-gray-900;
	@apply shadow shadow-gray-400 active:shadow-none active:ring-0 active:ring-offset-0 dark:shadow-black;
	@apply hover:decoration-0;

	&.inherit {
		@apply text-current;
	}
	&:not(.dense) {
		@apply px-3 py-2;
		&.outline {
			@apply px-2.5 py-1.5;
		}
	}
	&.dense {
		@apply px-2 py-1;
		&.outline {
			@apply px-1.5 py-0.5;
		}
	}

	&.outline {
		@apply border-2 border-solid border-current bg-transparent;
	}
	&.text {
		@apply bg-gray-500/0 shadow-none outline-none dark:drop-shadow-2xl dark:hover:bg-gray-500/40 dark:hover:text-white;

		&:active,
		&:hover,
		&:focus {
			@apply bg-gray-300/30 outline-none ring-current dark:bg-gray-200/20;
		}
		&:focus {
			@apply ring-1;
		}
		&.disabled {
			@apply bg-transparent text-gray-500 hover:bg-transparent hover:text-gray-500 dark:text-gray-300;
		}
	}

	&.disabled {
		@apply disabledBase;
		&.outline {
			@apply border-gray-400;
		}
	}

	// solid/default button styles for each color
	&:not(.text, .outline, .disabled) {
		&.mirusOrange {
			@apply bg-orange-600 focus:bg-orange-500;
		}
		&.mirusGray {
			@apply bg-gray-500 focus:bg-gray-600;
		}
		&.darkGray {
			@apply bg-gray-700 focus:bg-gray-800;
		}
		&.mirusWhite {
			@apply bg-white text-sf-charcoal focus:bg-gray-200;
		}
		&.infoBlue {
			@apply bg-blue-600 focus:bg-blue-700;
		}
		&.successGreen {
			@apply bg-green-700 focus:bg-green-800;
		}
		&.errorRed {
			@apply bg-red-700 focus:bg-red-800;
		}
		&.warnYellow {
			@apply bg-yellow-500 focus:bg-yellow-600;
		}
	}

	// hover styles, omitting variants that will need to override this
	&:not(.text, .disabled) {
		&.mirusOrange {
			@apply hover:bg-orange-500 dark:hover:text-white;
		}
		&.mirusGray {
			@apply hover:bg-gray-600;
		}
		&.darkGray {
			@apply hover:bg-gray-800 dark:hover:bg-gray-900;
		}
		&.mirusWhite {
			@apply hover:bg-gray-200 hover:ring-white;
		}
		&.infoBlue {
			@apply hover:bg-blue-700;
		}
		&.successGreen {
			@apply hover:bg-green-800;
		}
		&.errorRed {
			@apply hover:bg-red-800;
		}
		&.warnYellow {
			@apply hover:bg-yellow-600;
		}
	}

	// custom colors for all enabled variants
	&:not(.disabled) {
		&.mirusOrange {
			@apply text-black hover:ring-orange-600 focus:ring-orange-600;
		}
		&.mirusGray {
			@apply text-white hover:ring-gray-600 focus:ring-gray-600;
		}
		&.darkGray {
			@apply text-white hover:ring-gray-700 focus:ring-gray-700;
		}
		&.mirusWhite {
			@apply focus:ring-white;
		}
		&.infoBlue {
			@apply text-white hover:ring-blue-800 focus:ring-blue-800;
		}
		&.successGreen {
			@apply text-white hover:ring-green-700 focus:ring-green-700;
		}
		&.errorRed {
			@apply text-white hover:ring-red-900 focus:ring-red-900;
		}
		&.warnYellow {
			@apply text-gray-800 hover:text-white hover:ring-yellow-600 focus:text-white focus:ring-yellow-600;
		}

		&.text,
		&.outline {
			@apply hover:bg-gray-50 focus:bg-gray-50 dark:hover:bg-gray-800 dark:focus:bg-gray-800;

			&.mirusOrange {
				@apply text-orange-700 dark:text-orange-500;
			}
			&.mirusGray {
				@apply text-gray-500 dark:text-gray-300;
			}
			&.darkGray {
				@apply text-gray-700 dark:text-black dark:hover:text-white dark:focus:text-white;
			}
			&.mirusWhite {
				@apply text-white drop-shadow hover:bg-gray-600 focus:bg-gray-600;
			}
			&.infoBlue {
				@apply text-blue-800 dark:text-blue-500;
			}
			&.successGreen {
				@apply text-green-800 dark:text-green-500;
			}
			&.errorRed {
				@apply text-red-700 dark:text-red-500;
			}
			&.warnYellow {
				@apply text-yellow-600 dark:text-yellow-400;
			}
		}
	}
}
</style>
