<template>
	<div :class="{ absolute: !$scopedSlots.activator }" class="flex">
		<slot v-if="$scopedSlots.activator" name="activator" v-bind="{ openDialog, closeDialog }" />
		<dialog
			ref="dialog"
			:role="alert && 'alertdialog'"
			class="fixed inset-0 z-20 m-auto flex max-h-[90vh] w-full !rounded-xl bg-white shadow-2xl transition ease-in-out backdrop:bg-gray-900/50 backdrop:transition dark:bg-gray-800 dark:text-gray-100 dark:shadow-black dark:backdrop:bg-gray-700/70"
			:class="
				open
					? 'pointer-events-auto scale-100 opacity-100 backdrop:opacity-100'
					: 'pointer-events-none scale-90 opacity-0 backdrop:opacity-0'
			"
			:style="{
				maxWidth: maxWidth ? maxWidth + 'px' : '90vw',
			}"
			:aria-labelledby="uid"
			:aria-describedby="ariaDescribedby"
		>
			<OnClickOutside
				class="flex max-h-full w-full flex-col items-stretch overflow-auto"
				tabindex="0"
				@trigger="closeDialog()"
			>
				<header
					v-if="$scopedSlots.header"
					class="sticky top-0 z-10 flex bg-gray-200 py-4 pl-5 text-2xl font-bold dark:bg-gray-700"
				>
					<span :id="uid">
						<slot name="header" />
					</span>
					<BaseButton
						v-if="!persistent"
						aria-label="close dialog"
						class="absolute right-4 top-1/2 z-20 !h-8 !w-8 -translate-y-1/2 !p-0"
						color="red"
						@click="closeDialog"
					>
						<FAIcon icon="times" />
					</BaseButton>
				</header>

				<article
					:class="[dense ? 'p-2' : 'p-4', $scopedSlots.header ? '!pt-4' : '!pt-2']"
					class="min-h-0 overflow-y-auto pb-4 shadow-inner dark:shadow-gray-900"
				>
					<slot />
				</article>

				<footer class="sticky bottom-0 z-10 flex flex-col bg-gray-100 dark:bg-gray-700">
					<div v-if="$scopedSlots.actions" class="flex justify-end gap-1 px-2 py-2">
						<slot name="actions" v-bind="{ closeDialog }" />
					</div>
					<span
						v-if="!persistent"
						class="bg-gray-300 p-2 text-center text-sm dark:bg-gray-700"
					>
						press
						<kbd>Esc</kbd>
						to close this dialog
					</span>
				</footer>
			</OnClickOutside>
		</dialog>
	</div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';

import BaseButton from '@/components/ui/BaseButton.vue';

const props = defineProps({
	value: { type: Boolean, default: false },
	maxWidth: { type: [Number, String], default: null },
	persistent: { type: Boolean, default: false },
	dense: { type: Boolean, default: false },
	ariaDescribedby: { type: String },
	alert: { type: Boolean, default: false },
});

const emit = defineEmits(['close', 'update:value']);

const dialog = ref(null);
const open = ref(false);
const uid = ref('dialog-heading-' + Math.random().toString(16).slice(2));

function openDialog() {
	open.value = true;
	dialog.value?.showModal();
	emit('update:value', true);
	document.querySelector(':root').classList.add('overflow-hidden');
}

function closeDialog(force) {
	if (open.value) {
		emit('close');

		if (!props.persistent || force) {
			open.value = false;
			emit('update:value', false);

			// allows the dialog backdrop to transition instead of disappearing immediately
			setTimeout(() => {
				dialog.value?.close();
				if (!document.querySelector('dialog[open]'))
					document.querySelector(':root').classList.remove('overflow-hidden');
			}, 150);
		}
	}
}

function escapeHandler(event) {
	if (event.key === 'Escape') {
		event.preventDefault();
		event.stopPropagation();
		closeDialog();
	}
}

watch(
	() => props.value,
	() => {
		if (props.value !== open.value) {
			props.value ? openDialog() : closeDialog(true);
		}
	}
);

onMounted(() => {
	dialog.value.addEventListener('keydown', escapeHandler);
	if (props.value) openDialog();
});
onUnmounted(() => {
	dialog.value?.removeEventListener('keydown', escapeHandler);
});
</script>

<style scoped></style>
