<template>
	<section
		:aria-labelledby="`${title.replaceAll(/\s/g, '_')}_table_caption`"
		:class="{
			'shadow-inner': inset,
			'shadow-inner max-md:p-1 md:p-2 xl:p-3': padding,
		}"
		class="flow-root max-h-full w-full overflow-auto rounded bg-gray-100 dark:bg-gray-900 dark:text-gray-100"
		tabindex="0"
	>
		<header
			v-if="showSearch || showTitle"
			class="sticky left-0 top-0 flex items-center px-2 py-4"
		>
			<TableTitle
				v-if="showTitle"
				class="grow md:basis-3/4"
				v-bind="{ headingLevel, title, description }"
			/>

			<TextFieldInput
				v-if="showSearch"
				:value.sync="search"
				class="grow md:basis-1/4"
				label="Search items"
				placeholder="search items"
			/>
		</header>

		<table
			class="relative z-0 inline-table max-h-full w-full divide-y divide-gray-300 overflow-hidden rounded dark:divide-gray-500"
		>
			<caption :id="`${title.replaceAll(/\s/g, '_')}_table_caption`" class="sr-only">
				{{
					title
				}}
			</caption>
			<thead class="rounded-top sticky top-0 z-10 w-auto max-w-full">
				<tr>
					<th
						v-for="header in headers"
						:key="header.value"
						:class="[
							dense
								? 'p-1 md:py-2 md:pl-2 md:pr-1.5 '
								: 'px-1.5 py-1 md:px-3 md:py-3.5 ',
							{
								'text-center': header.centered,
							},
						]"
						class="bg-gray-200 text-left text-sm font-semibold text-gray-900 dark:bg-gray-800 dark:text-gray-100"
						scope="col"
					>
						<div
							class="inline-flex shrink-0 items-center justify-center gap-2 whitespace-nowrap"
						>
							<slot :name="`header.${header.value}`" v-bind="{ ...header }">
								{{ header.text }}
							</slot>

							<!--todo: update this with sort info for screen readers -->
							<button
								v-if="header.sortable"
								:class="{
									'rotate-180': sortBy === header.value && sortDescending,
									'opacity-20 hover:opacity-60 dark:opacity-40 dark:hover:opacity-80':
										sortBy !== header.value,
								}"
								class="sortButton"
								@click="updateSort(header.value)"
							>
								<FAIcon icon="arrow-up" />
							</button>
						</div>
					</th>

					<th
						v-if="hasExpandedSlot && hasExpandableItem"
						class="bg-gray-200 text-left text-sm font-semibold text-gray-900 dark:bg-gray-800 dark:text-gray-100"
					>
						<span class="sr-only">Expand icon</span>
					</th>
				</tr>
			</thead>

			<tbody
				v-if="filteredSortedItems.length === 0"
				class="divide-y divide-gray-200 bg-white dark:bg-gray-900"
			>
				<tr>
					<td
						:colspan="maxColSpan"
						class="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500"
					>
						No items found
					</td>
				</tr>
			</tbody>

			<tbody v-else class="container sticky bottom-0">
				<template v-for="item in paginatedItems">
					<!-- Core data elements of table -->
					<tr
						:key="`${item[itemUniqueKey]}-row`"
						:class="{
							'cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800':
								itemIsExpandable(item),
						}"
						:tabindex="itemIsExpandable(item) ? 0 : -1"
						class="item bg-white text-gray-500 even:bg-gray-100 dark:bg-gray-600 dark:text-gray-200 dark:even:bg-gray-700 dark:even:text-white"
						@click="() => expand(item)"
						@keydown.enter="() => expand(item)"
					>
						<td
							v-for="header in headers"
							:key="header.value"
							:class="[
								dense ? 'lg:px-1.5 lg:py-2' : 'md:px-1.5 md:py-2 lg:px-3 lg:py-4',
								header.wrap ? 'break-words' : 'whitespace-nowrap',

								{
									'max-w-[10vw] truncate': header.truncate,
									'text-center': header.centered,
								},
							]"
							class="truncate p-1 text-sm"
							:title="item[header.value]"
						>
							<slot :name="`item.${header.value}`" v-bind="{ ...item }">
								{{ item[header.value] }}
							</slot>
						</td>

						<template v-if="hasExpandedSlot && hasExpandableItem">
							<td v-if="itemIsExpandable(item)">
								<FAIcon
									:icon="
										expandedItems.includes(item[itemUniqueKey])
											? 'minus'
											: 'plus'
									"
									class="pr-4"
								/>
							</td>
							<td v-else></td>
						</template>
					</tr>
					<!-- Expandable details -->
					<tr
						v-if="itemIsExpandable(item) && expandedItems.includes(item[itemUniqueKey])"
						:key="`${item[itemUniqueKey]}-details`"
						class="item"
					>
						<td :colspan="maxColSpan" class="bg-gray-200 shadow-inner dark:bg-gray-800">
							<slot name="item_expanded" v-bind="{ ...item }" />
						</td>
					</tr>
				</template>
			</tbody>

			<tfoot
				v-if="(showPagination && filteredSortedItems.length > 5) || $slots.toolbar"
				class="sticky bottom-0 z-0 shadow"
			>
				<tr>
					<td :colspan="maxColSpan">
						<div
							class="flex flex-col flex-wrap items-start justify-start gap-4 overflow-x-auto bg-gray-100 p-1 dark:bg-gray-900 sm:flex-row md:items-center md:justify-end md:p-2 lg:p-4"
						>
							<TablePaginator
								v-if="showPagination && filteredSortedItems.length > 5"
								:end-index.sync="endIndex"
								:item-count="filteredSortedItems.length"
								:rows-per-page.sync="rowsPerPage"
								:start-index.sync="startIndex"
								class="sticky left-0"
								:title="title"
							/>
							<slot name="toolbar" />
						</div>
					</td>
				</tr>
			</tfoot>
		</table>
	</section>
</template>

<script setup>
import { computed, ref, watchEffect, useSlots } from 'vue';
import TextFieldInput from '@/components/ui/TextFieldInput';
import TablePaginator from '@/components/ui/TablePaginator.vue';
import TableTitle from '@/components/ui/TableTitle.vue';

const props = defineProps({
	items: { type: Array, required: true },
	headers: { type: Array, required: true },
	itemUniqueKey: { type: String, required: true },

	headingLevel: { type: String, default: 'h3' },
	title: { type: String, default: 'Sortable Table' },
	description: { type: String, default: null },

	showTitle: { type: Boolean, default: false },
	showSearch: { type: Boolean, default: false },
	showPagination: { type: Boolean, default: false },
	expandAll: { type: Boolean, default: true },

	dense: { type: Boolean, default: false },
	inset: { type: Boolean, default: true },
	padding: { type: Boolean, default: true },

	initialSortBy: { type: String, default: null },
});
const slots = useSlots();

const sortBy = ref(props.initialSortBy);
const sortDescending = ref(false);
const search = ref('');
const rowsPerPage = ref(5);
const startIndex = ref(0);
const endIndex = ref(4);
const expandedItems = ref([]);

const hasExpandedSlot = computed(() => Boolean(slots.item_expanded));
const hasExpandableItem = computed(
	() => props.expandAll || props.items.some(item => item.expandable)
);

const maxColSpan = computed(() => {
	// if expanded we add another col for the plus icon
	if (hasExpandedSlot.value && hasExpandableItem.value) {
		return props.headers.length + 1;
	}
	return props.headers.length;
});

const filterableItems = computed(() =>
	props.headers.filter(({ filterable }) => Boolean(filterable))
);

const filteredSortedItems = computed(() => {
	let finalItemList = [...props.items];
	if (search.value?.trim() !== '') {
		// get list of searchable fields for each item
		finalItemList = finalItemList.filter(item =>
			filterableItems.value.some(({ value }) =>
				item[value]?.toLowerCase().includes(search.value?.toLowerCase().trim())
			)
		);
	}
	if (sortBy.value) {
		finalItemList.sort((a, b) => {
			if (a[sortBy.value] < b[sortBy.value]) {
				return -1;
			} else if (a[sortBy.value] > b[sortBy.value]) {
				return 1;
			}
			return 0;
		});
		if (sortDescending.value) {
			finalItemList.reverse();
		}
	}
	return finalItemList;
});

const paginatedItems = computed(() => {
	return props.showPagination
		? [...filteredSortedItems.value].slice(startIndex.value, endIndex.value)
		: filteredSortedItems.value;
});

function updateSort(path) {
	if (path !== sortBy.value) {
		sortBy.value = path;
		sortDescending.value = false;
	} else {
		sortDescending.value = !sortDescending.value;
	}
}

function expand(item) {
	if (!itemIsExpandable(item)) {
		return;
	} else if (expandedItems.value.includes(item[props.itemUniqueKey])) {
		// collapse if already expanded
		const i = expandedItems.value.indexOf(item[props.itemUniqueKey]);
		expandedItems.value.splice(i, 1);
	} else {
		expandedItems.value.push(item[props.itemUniqueKey]);
	}
}

function itemIsExpandable(item) {
	return hasExpandedSlot.value && (item.expandable || props.expandAll);
}

watchEffect(() => {
	if (!props.items.every(item => Object.hasOwn(item, props.itemUniqueKey))) {
		throw new Error(
			`SortableTable was provided an itemUniqueKey of ${props.itemUniqueKey}, but not every item provided has that property.`
		);
	}
});

defineExpose({
	rowsPerPage,
});
</script>

<style scoped>
.container {
	position: relative;
	padding: 0;
}
.sortButton {
	@apply aspect-square self-stretch rounded-full px-1 transition;
	@apply text-gray-500 dark:text-gray-100;
	@apply hover:text-gray-800 dark:hover:text-gray-100;
	@apply focus:text-gray-800 focus:opacity-100 focus:outline-0 focus:ring-1 focus:ring-gray-800 focus:ring-offset-1 dark:focus:text-gray-100;
}
</style>
