<template>
	<div
		v-if="isMirusUser"
		:class="[
			inline ? 'inline-flex' : 'flex',
			dense ? 'p-1' : 'p-2',
			column ? 'flex-col' : 'flex-row',
		]"
		class="min-w-fit grow-0 items-center gap-1 rounded-md border-4 border-dotted border-orange-700 bg-orange-100 dark:border-orange-900 dark:bg-orange-500"
	>
		<div
			:class="[inline || column ? '' : '-rotate-90', dense ? '' : 'px-1']"
			class="text-center text-xs uppercase leading-none tracking-widest"
		>
			mirus<br v-if="!dense" /><span>&nbsp;</span>only
		</div>

		<div class="flex items-center gap-1 rounded-lg border-2 border-dotted border-gray-500 p-1">
			<slot />
		</div>
	</div>
	<div v-else-if="$slots.fallback">
		<slot name="fallback" />
	</div>
</template>

<script setup>
import useUserStore from '@/stores/user';
import { storeToRefs } from 'pinia';
defineProps({
	inline: { type: Boolean, default: false },
	dense: { type: Boolean, default: false },
	column: { type: Boolean, default: false },
});
const userStore = useUserStore();
const { isMirusUser } = storeToRefs(userStore);
</script>

<style scoped></style>
