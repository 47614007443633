<template>
	<tr>
		<td>
			<h4 class="ml-4 text-base">
				<FAIcon class="mb-0.5 -scale-x-100" icon="arrow-turn-up" />
				Refund
				<MirusOnlyBorder inline>
					for transaction&nbsp;
					<a
						class="text-left font-bold"
						:href="
							'//braintreegateway.com/merchants/3g737q2bszkrq2v7/transactions/' +
							id +
							'/'
						"
						target="_blank"
						rel="noopener noreferrer"
					>
						{{ refunded_transaction_id }}
					</a>
				</MirusOnlyBorder>
			</h4>
		</td>
		<td>
			<MirusOnlyBorder v-if="!compDeduct" inline>
				<a
					class="text-left font-bold"
					:href="
						'//braintreegateway.com/merchants/3g737q2bszkrq2v7/transactions/' + id + '/'
					"
					target="_blank"
					rel="noopener noreferrer"
				>
					{{ id }}
				</a>
			</MirusOnlyBorder>
		</td>

		<td>
			<p class="m-0 text-right text-base text-red">{{ amountString }}</p>
		</td>
	</tr>
</template>

<script setup>
import { computed } from 'vue';

import { moneyFormatter } from '@/utils';
import MirusOnlyBorder from '@/components/common/MirusOnlyBorder';

const props = defineProps({
	compDeduct: { type: Boolean },
	internal: { type: Boolean, required: true },
	id: { type: String, required: true },
	refunded_transaction_id: { type: String, required: true },
	amount: { type: [String, Number], required: true },
});

const amountString = computed(() => {
	return moneyFormatter.format(props.amount * -1);
});
</script>

<style scoped lang="scss">
.rotate-90 {
	transform: rotate(90deg);
}
</style>
