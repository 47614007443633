<template>
	<div class="flex flex-col gap-2">
		<ProgressBar v-if="jobListing === null" text="Loading..." class="rounded-md bg-white p-2" />
		<div v-else class="overflow-auto rounded-md bg-white p-2">
			<ValidationObserver ref="observer" v-slot="{ valid, handleSubmit }" slim>
				<form class="m-4 flex w-1/2 flex-col gap-2" @submit.prevent="handleSubmit(saveUrl)">
					<p class="mb-2 text-lg">
						Microsite Url:
						<a :href="jobListing?.microsite_url" rel="noopener noreferrer">{{
							jobListing?.microsite_url
						}}</a>
					</p>
					<ValidationProvider
						ref="listingUrlValidator"
						v-slot="{ errors }"
						:rules="{
							url: true,
							required: true,
							regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
						}"
						name="Listing Url"
					>
						<TextFieldInput
							id="listing-url"
							ref="listingUrlInput"
							:value.sync="listingUrl"
							label="Enter url to override microsite url"
						>
							<template v-if="errors[0]" #message>
								<span>{{ errors[0] }}</span>
							</template>
						</TextFieldInput>
					</ValidationProvider>
					<div class="mt-1 flex items-center gap-2">
						<BaseButton class="" :disabled="!valid" color="primary" @click="saveUrl()">
							<span class="text-sm font-medium">Save & Crawl</span>
						</BaseButton>
						<BaseButton class="" color="gray" @click="crawlJobs()">
							<span class="text-sm font-medium">Crawl</span>
						</BaseButton>
						<p v-if="!isCrawling" class="mb-0">
							Last crawl:
							{{ lastCrawlRelativeDate }}
						</p>
						<ProgressBar v-else />
					</div>
				</form>
			</ValidationObserver>
			<div v-if="jobListing?.jobs?.length > 0" class="m-4 mt-6">
				<p class="mb-0 pb-1 text-lg">Open Positions</p>
				<ul class="flex flex-col gap-2 pl-0">
					<li v-for="job in jobListing.jobs" :key="job.url">
						<a :href="job.url">{{ job.title }}</a>
					</li>
				</ul>
			</div>
			<div v-else-if="isCareerPlugUrl()" class="m-4 mt-6">
				<p v-if="jobListing?.active">There are no open jobs right now.</p>
				<p v-else>Couldn't find any jobs. There may be an issue with the url.</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { parseISO, formatDistanceToNow } from 'date-fns';

import BaseButton from '@/components/ui/BaseButton';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

import useJobsStore from '@/stores/agent/agent-jobs';

const jobsStore = useJobsStore();
const { jobListing } = storeToRefs(jobsStore);

const listingUrl = ref('');
const isCrawling = ref(false);

const lastCrawlRelativeDate = computed(() =>
	jobListing.value?.last_crawl
		? formatDistanceToNow(parseISO(jobListing.value?.last_crawl), {
				addSuffix: true,
		  })
		: ''
);

const url = computed(() => jobListing.value?.url_override || jobListing.value?.microsite_url);

function isCareerPlugUrl() {
	const urlOrNull = url?.value;
	return urlOrNull ? urlOrNull.includes('sfagentjobs.com') : false;
}

async function getJobs() {
	await jobsStore.get();
	listingUrl.value = jobListing.value.url;
}

async function crawlJobs() {
	isCrawling.value = true;
	await jobsStore.crawl();
	listingUrl.value = jobListing.value.url;
	isCrawling.value = false;
}

async function saveUrl() {
	jobsStore.update(listingUrl.value);
}

onMounted(async () => {
	await getJobs();
	listingUrl.value = jobListing.value.url_override;
});
</script>
