<template>
	<label
		:class="[disabled ? 'cursor-not-allowed' : 'cursor-pointer']"
		:for="inputID"
		class="checkboxLabel group relative inline-flex items-center gap-2"
	>
		<span class="relative">
			<input
				:id="inputID"
				:checked="checked"
				:class="[colorClass, roundedClass, { disabled }]"
				:disabled="disabled"
				:name="name"
				:value="value"
				class="checkboxInput"
				type="checkbox"
				@input="$emit('update:checked', $event.target.checked)"
			/>
			<svg
				class="absolute left-0 top-0 h-full w-full"
				viewBox="0 0 16 16"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"
				/>
			</svg>
		</span>
		<span :class="disabled ? 'text-gray-400' : 'text-gray-800 dark:text-gray-300'">
			<slot />
		</span>
	</label>
</template>

<script setup>
import { computed } from 'vue';
import { getColorClass, getRoundedClass } from '@/utils/styles';

defineEmits(['update:checked']);

const props = defineProps({
	checked: { type: Boolean, required: true },
	name: { type: String, required: true },
	value: { default: true },
	rounded: { type: [String, Boolean], default: 'sm' },
	disabled: { type: Boolean, default: false },
	color: { type: String, default: 'gray' },
});

const roundedClass = computed(() => getRoundedClass(props.rounded));
const colorClass = computed(() => getColorClass(props.color));
const inputID = computed(() => `checkbox-option-${props.name.replaceAll(/\s/g, '_')}`);
</script>

<style lang="scss" scoped>
.checkboxLabel {
	@apply rounded ring-offset-2 transition focus-within:ring-2 focus-within:ring-orange-500 dark:ring-offset-gray-800;

	.mirusOrange {
		@apply text-orange-500;
	}
	.successGreen {
		@apply text-green-600;
	}
	.mirusGray {
		@apply text-gray-600 dark:text-gray-400;
	}
	.errorRed {
		@apply text-red-500;
	}
	.infoBlue {
		@apply text-blue-500;
	}
	.warnYellow {
		@apply text-yellow-300;
	}

	&.disabled {
		@apply text-gray-400;
	}
}
.checkboxInput {
	@apply accent-current ring-1 ring-current transition-all checked:bg-current dark:bg-gray-900 dark:checked:bg-white;
	@apply enabled:group-hover:ring-2 enabled:group-hover:ring-current;
	@apply focus:ring-1 focus:ring-current focus:ring-offset-0;
	@apply disabled:cursor-not-allowed;
	&,
	&:checked {
		background-image: none;
	}

	& + svg {
		@apply fill-white opacity-0 transition-opacity dark:fill-gray-900;
	}

	&:checked + svg {
		@apply opacity-100;
	}
}
</style>
