<template>
	<ValidationObserver v-slot="{ valid }" class="container">
		<div class="px-3 pb-3">
			<h3 class="text-gray-500">Link Generator for Tracking Codes/Campaign IDs</h3>

			<p class="text-md text-gray-500">
				This section allows you to generate a new tracking link which you can use in your
				online marketing campaigns.
			</p>

			<div class="mx-3 my-5 flex flex-col space-x-4 lg:flex-row">
				<ValidationProvider v-slot="{ errors, validate }" name="Domain" rules="required">
					<BaseSelect
						id="domain-select"
						:value.sync="domain"
						:items="domainList"
						:error-messages="errors"
						label="Select a Domain"
						item-text-key="domain_name"
						item-value-key="domain_name"
						outlined
						show-label
						value-type="object"
						@update:value="validate"
					/>
				</ValidationProvider>

				<ValidationProvider v-slot="{ errors, validate }" name="Landing Page" class="grow">
					<BaseSelect
						id="landing-page-select"
						:value.sync="landingPage"
						:items="landingPages"
						:error-messages="errors"
						label="Landing Page"
						outlined
						show-label
						capitalize
						value-type="object"
						@update:value="validate"
					/>
				</ValidationProvider>
				<TextFieldInput
					label="State Code"
					:hint="`${stateCodePlusCode} (read only)`"
					:value="agent.state_code"
					disabled
				/>

				<TextFieldInput
					ref="agentCode"
					label="Agent Code"
					:value="agent.agent_code"
					disabled
				/>

				<ValidationProvider
					v-slot="{ errors, validate }"
					name="Traffic Source"
					rules="required"
				>
					<BaseSelect
						id="traffic-source-select"
						:value.sync="trafficSource"
						:items="trafficSources"
						:error-messages="errors"
						label="Traffic Source"
						shadow
						outlined
						show-label
						value-type="object"
						@update:value="validate"
					/>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors, validate }"
					name="Code"
					:rules="{ required: true, between: { min: 1, max: 999 } }"
				>
					<TextFieldInput
						v-model="code"
						label="Creative Code"
						:error-messages="errors"
						maxlength="3"
						minlength="3"
						placeholder="001 - 699"
						pattern="\d{3}"
						min="001"
						max="699"
						required
						@update:value="validate"
					/>
				</ValidationProvider>
			</div>

			<div class="mx-3 mt-4 flex items-center space-x-4">
				<div
					id="copy"
					class="relative rounded bg-gray-100 px-4 py-4 text-sm font-medium lg:text-lg"
				>
					<p
						class="absolute top-0 -translate-y-1/2 transform rounded border bg-white px-1 py-0.5 text-xs font-medium"
					>
						Generated Link:
					</p>
					{{ fullUrlString }}
				</div>

				<BaseButton :disabled="!valid" color="primary" @click="copyToClipboard()">
					<FAIcon icon="copy" />
					<span class="ml-1 text-sm font-medium">Copy</span>
				</BaseButton>
			</div>

			<BaseSnackbar :display="displaySnackbar" :timeout="5000">
				<BaseAlert :type="copiedLinkType">
					{{ copiedLinkMessage }}
				</BaseAlert>
			</BaseSnackbar>

			<div class="mx-3 mb-3 mt-8">
				<BaseExpansionList :items="linkGeneratorFAQs">
					<template #content="{ content }">
						<div class="p-4" v-html="content"></div>
					</template>
				</BaseExpansionList>
			</div>
			<div class="mx-3 mb-3 mt-4" />
		</div>
	</ValidationObserver>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { extend } from 'vee-validate';

import useDomainStore from '@/stores/agent/agent-domains';
import useAgentStore from '@/stores/agent/agent';

import BaseSnackbar from '@/components/ui/BaseSnackbar';
import BaseAlert from '@/components/ui/BaseAlert';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';

const linkGeneratorFAQs = [
	{
		path: 'link Generator',
		label: 'What is a Link Generator?',
		content: `<p>
								Agents and their digital advertising vendors are highly encouraged
								to use campaign IDs for their M1 domain and/or Quote Landing page
								URLs in order to track different digital advertising campaigns and
								tactics. If campaign IDs are not set up and attached to the URL
								properly, detailed campaign reporting cannot be provided.
							</p>

							<p>
								There are two options available for you to use to generate a
								campaign ID (aka tracking code) for your M1 domain or domain/Quote
								Landing page URL.
							</p>
							<ol>
								<li>
									Use the Link Generator tool above, use the dropdown options to
									the populate fields. The URL with campaign ID code will be
									displayed at the bottom of the Link Generator tool.
								</li>
								<li>
									Or use the State Farm Agent Quote Landing Page (AQLP) Set Up
									Guide:
									<a
										href="http://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/quote_landing_page/AgentQuoteLandingPageSetUp.shtml"
										>Link</a
									>
								</li>
							</ol>`,
	},
	{
		path: 'Usage of Links',
		label: 'What do I do with the link I have generated?',
		content: `Use this URL to link to your online ad/marketing campaign(s), provide it
					to your marketing vendor or save the URL somewhere until you are ready
					to use it.`,
	},
	{
		path: 'Campaign ID data',
		label: 'Where do I see my Campaign ID data?',
		content: `<p>
								Regardless of which page you are using, M1 Homepage or a Quote
								Landing Page, daily metrics will be available here, in the "Stats" tab of your Campaigns page.
								This data will help you track how well your digital marketing
								efforts are performing.
							</p>
							<p>
								If you are using the Quote Landing Page (domain/quote) URL with a
								campaign ID and you have campaign data for the month, reports will
								be automatically emailed (to the agent only) by the seventh of each
								month by ASR. If there is no AQLP data/activity for the month, you
								will not receive an email from ASR.
							</p>
							<ul>
								<li>
									The email's subject will be: Agent Quote Landing Page Report for
									Month Year.
								</li>
								<li>
									Note: The report from ASR will not include Agent Tagged Media
									(ATM) AQLP reporting data, you will need to visit SF Navigator
									for all data related to ATM.
								</li>
							</ul>
							<p>
								For any questions regarding your AQLP report, please contact Agency
								Sales Resource (ASR) by completing the ASR Online Contact Form or
								for urgent issues, call
								<a href="tel:+18778892294">877-889-2294</a>.
							</p>`,
	},
];

const trafficSources = [
	{ value: 'BD', text: 'Bing/Yahoo Ads Desktop' },
	{ value: 'BM', text: 'Bing/Yahoo Ads Mobile' },
	{ value: 'DD', text: 'Desktop Display' },
	{ value: 'ET', text: 'Email and Email Template' },
	{ value: 'FD', text: 'Facebook Desktop' },
	{ value: 'FM', text: 'Facebook Mobile' },
	{ value: 'FN', text: 'Facebook Newsfeed' },
	{ value: 'FP', text: 'Facebook Promoted Post' },
	{ value: 'GD', text: 'Google Adwords Desktops' },
	{ value: 'GM', text: 'Google Adwords Mobile' },
	{ value: 'HU', text: 'Hulu' },
	{ value: 'IH', text: 'iHeart Radio' },
	{ value: 'IS', text: 'Imprinted Stationary' },
	{ value: 'EM', text: 'In-Book Email Marketing' },
	{ value: 'IY', text: 'Internet Yellow Pages Ads' },
	{ value: 'KB', text: 'KellyBlueBook.com' },
	{ value: 'LD', text: 'LinkedIn Desktop' },
	{ value: 'LM', text: 'LinkedIn Mobile' },
	{ value: 'MD', text: 'Mobile Display' },
	{ value: 'PD', text: 'Pandora' },
	{ value: 'SP', text: 'Spotify' },
	{ value: 'FM', text: 'X (formerly Twitter) Desktop' },
	{ value: 'TM', text: 'X (formerly Twitter) Mobile' },
	{ value: 'VE', text: 'Video Enhancement' },
	{ value: 'VP', text: 'Video Pre-Roll' },
	{ value: 'WT', text: 'Weather.com' },
	{ value: 'YD', text: 'Yelp Desktop' },
	{ value: 'Ym', text: 'Yelp Mobile' },
	{ value: 'U1', text: 'Other/Undefined 1' },
	{ value: 'U2', text: 'Other/Undefined 2' },
	{ value: 'U3', text: 'Other/Undefined 3' },
	{ value: 'U4', text: 'Other/Undefined 4' },
	{ value: 'U5', text: 'Other/Undefined 5' },
	{ value: 'U6', text: 'Other/Undefined 6' },
	{ value: 'U7', text: 'Other/Undefined 7' },
	{ value: 'U8', text: 'Other/Undefined 8' },
	{ value: 'U9', text: 'Other/Undefined 9' },
];
extend('code', {
	validate: value => {
		return value.match(/[^\d{3}$]/) || `Code must be exactly three numbers`;
	},
});

const domainStore = useDomainStore();

const domain = ref(null);
const landingPage = ref(null);
const trafficSource = ref(null);
const code = ref(`001`);
const routes = ref([]);

const displaySnackbar = ref(false);
const copiedLinkType = ref('success');
const copiedLinkMessage = ref('');

const { domains } = storeToRefs(domainStore);

const domainList = computed(() => {
	return domains.value?.filter(domain => domain.active) ?? [];
});

const m1LandingPages = computed(() => {
	const allOptions = [
		{ value: 'quote', text: 'Homepage QLP', key: 'mxquotelanding_general' },
		{ value: 'quote/auto', text: 'Auto Quote QLP', key: 'mxquotelanding_auto' },
		{ value: 'quote/condo', text: 'Condo Quote QLP', key: 'mxquotelanding_condo' },
		{
			value: 'quote/homeowners',
			text: 'Homeowners Quote QLP',
			key: 'mxquotelanding_homeowners',
		},
		{ value: 'quote/hospital', text: 'Hospital Quote QLP', key: 'mxquotelanding_hospital' },
		{ value: 'quote/life', text: 'Life Quote QLP', key: 'mxquotelanding_life' },
		{ value: 'quote/medicare', text: 'Medicare Quote QLP', key: 'mxquotelanding_medicare' },
		{ value: 'quote/pet', text: 'Pet Quote QLP', key: 'mxquotelanding_pet' },
		{ value: 'quote/renters', text: 'Renters Quote QLP', key: 'mxquotelanding_renters' },
		{ value: 'auto', text: 'Auto QLP', key: 'mxquotelanding_auto_root' },
		{ value: 'condo', text: 'Condo QLP', key: 'mxquotelanding_condo_root' },
		{ value: 'homeowners', text: 'Homeowners QLP', key: 'mxquotelanding_homeowners_root' },
		{ value: 'hospital', text: 'Hospital QLP', key: 'mxquotelanding_hospital_root' },
		{ value: 'life', text: 'Life QLP', key: 'mxquotelanding_life_root' },
		{ value: 'medicare', text: 'Medicare QLP', key: 'mxquotelanding_medicare_root' },
		{ value: 'pet', text: 'Pet QLP', key: 'mxquotelanding_pet_root' },
		{ value: 'renters', text: 'Renters QLP', key: 'mxquotelanding_renters_root' },
		{ value: 'quote', text: 'Homepage SF QLP', key: 'sfquotelanding_general' },
		{ value: 'quote/auto', text: 'Auto Quote SF QLP', key: 'sfquotelanding_auto' },
		{ value: 'quote/condo', text: 'Condo Quote SF QLP', key: 'sfquotelanding_condo' },
		{
			value: 'quote/homeowners',
			text: 'Homeowners Quote SF QLP',
			key: 'sfquotelanding_homeowners',
		},
		{ value: 'quote/hospital', text: 'Hospital Quote SF QLP', key: 'sfquotelanding_hospital' },
		{ value: 'quote/life', text: 'Life Quote SF QLP', key: 'sfquotelanding_life' },
		{ value: 'quote/medicare', text: 'Medicare Quote SF QLP', key: 'sfquotelanding_medicare' },
		{ value: 'quote/pet', text: 'Pet Quote SF QLP', key: 'sfquotelanding_pet' },
		{ value: 'quote/renters', text: 'Renters Quote SF QLP', key: 'sfquotelanding_renters' },
		{ value: 'auto', text: 'Auto SF QLP', key: 'sfquotelanding_auto_root' },
		{ value: 'condo', text: 'Condo SF QLP', key: 'sfquotelanding_condo_root' },
		{ value: 'homeowners', text: 'Homeowners SF QLP', key: 'sfquotelanding_homeowners_root' },
		{ value: 'hospital', text: 'Hospital SF QLP', key: 'sfquotelanding_hospital_root' },
		{ value: 'life', text: 'Life SF QLP', key: 'sfquotelanding_life_root' },
		{ value: 'medicare', text: 'Medicare SF QLP', key: 'sfquotelanding_medicare_root' },
		{ value: 'pet', text: 'Pet SF QLP', key: 'sfquotelanding_pet_root' },
		{ value: 'renters', text: 'Renters SF QLP', key: 'sfquotelanding_renters_root' },
	];

	const options = filterLandingPageOptions(allOptions);

	if (!['parked', 'old_parked'].includes(domain.value?.product_type)) {
		options.unshift({ value: null, text: 'Homepage' });
	}

	return options;
});

const m2LandingPages = computed(() => {
	const allOptions = [
		{ value: 'insurance', text: 'Insurance page', key: 'mxlineofbusiness_general' },
		{ value: 'insurance/auto', text: 'Auto Insurance page', key: 'mxlineofbusiness_auto' },
		{
			value: 'insurance/motorcycle',
			text: 'Motorcycle Insurance page',
			key: 'mxlineofbusiness_motorcycle',
		},
		{
			value: 'insurance/homeowners',
			text: 'Homeowners Insurance page',
			key: 'mxlineofbusiness_homeowners',
		},
		{ value: 'insurance/condo', text: 'Condo Insurance page', key: 'mxlineofbusiness_condo' },
		{
			value: 'insurance/renters',
			text: 'Renters Insurance page',
			key: 'mxlineofbusiness_renters',
		},
		{
			value: 'insurance/business',
			text: 'Business Insurance page',
			key: 'mxlineofbusiness_business',
		},
		{ value: 'insurance/life', text: 'Life Insurance page', key: 'mxlineofbusiness_life' },
		{ value: 'insurance/pet', text: 'Pet Insurance page', key: 'mxlineofbusiness_pet' },
		{
			value: 'insurance/recreational-vehicles',
			text: 'Rec Vehicles Insurance page',
			key: 'mxlineofbusiness_recreational-vehicles',
		},
		{ value: 'insurance/boat', text: 'Boat Insurance page', key: 'mxlineofbusiness_boat' },
		{ value: 'office2', text: 'Locations-Office 2', key: 'mxoffice2' },
		{ value: 'reviews', text: 'Reviews', key: 'mxreviews' },
		{ value: 'jobs', text: 'Jobs', key: 'mxjobslisting' },
		{ value: 'quote', text: 'Homepage QLP', key: 'mxquotelanding_general' },
		{ value: 'quote/auto', text: 'Auto Quote QLP', key: 'mxquotelanding_auto' },
		{ value: 'quote/condo', text: 'Condo Quote QLP', key: 'mxquotelanding_condo' },
		{
			value: 'quote/homeowners',
			text: 'Homeowners Quote QLP',
			key: 'mxquotelanding_homeowners',
		},
		{ value: 'quote/hospital', text: 'Hospital Quote QLP', key: 'mxquotelanding_hospital' },
		{ value: 'quote/life', text: 'Life Quote QLP', key: 'mxquotelanding_life' },
		{ value: 'quote/medicare', text: 'Medicare Quote QLP', key: 'mxquotelanding_medicare' },
		{ value: 'quote/pet', text: 'Pet Quote QLP', key: 'mxquotelanding_pet' },

		{ value: 'quote/renters', text: 'Renters Quote QLP', key: 'mxquotelanding_renters' },
		{ value: 'auto', text: 'Auto QLP', key: 'mxquotelanding_auto_root' },
		{ value: 'condo', text: 'Condo QLP', key: 'mxquotelanding_condo_root' },
		{ value: 'homeowners', text: 'Homeowners QLP', key: 'mxquotelanding_homeowners_root' },
		{ value: 'hospital', text: 'Hospital QLP', key: 'mxquotelanding_hospital_root' },
		{ value: 'life', text: 'Life QLP', key: 'mxquotelanding_life_root' },
		{ value: 'medicare', text: 'Medicare QLP', key: 'mxquotelanding_medicare_root' },
		{ value: 'pet', text: 'Pet QLP', key: 'mxquotelanding_pet_root' },
		{ value: 'renters', text: 'Renters QLP', key: 'mxquotelanding_renters_root' },
		{ value: 'quote', text: 'Homepage SF QLP', key: 'sfquotelanding_general' },
		{ value: 'quote/auto', text: 'Auto Quote SF QLP', key: 'sfquotelanding_auto' },
		{ value: 'quote/condo', text: 'Condo Quote SF QLP', key: 'sfquotelanding_condo' },
		{
			value: 'quote/homeowners',
			text: 'Homeowners Quote SF QLP',
			key: 'sfquotelanding_homeowners',
		},
		{ value: 'quote/hospital', text: 'Hospital Quote SF QLP', key: 'sfquotelanding_hospital' },
		{ value: 'quote/life', text: 'Life Quote SF QLP', key: 'sfquotelanding_life' },
		{ value: 'quote/medicare', text: 'Medicare Quote SF QLP', key: 'sfquotelanding_medicare' },
		{ value: 'quote/pet', text: 'Pet Quote SF QLP', key: 'sfquotelanding_pet' },
		{ value: 'quote/renters', text: 'Renters Quote SF QLP', key: 'sfquotelanding_renters' },
		{ value: 'auto', text: 'Auto SF QLP', key: 'sfquotelanding_auto_root' },
		{ value: 'condo', text: 'Condo SF QLP', key: 'sfquotelanding_condo_root' },
		{ value: 'homeowners', text: 'Homeowners SF QLP', key: 'sfquotelanding_homeowners_root' },
		{ value: 'hospital', text: 'Hospital SF QLP', key: 'sfquotelanding_hospital_root' },
		{ value: 'life', text: 'Life SF QLP', key: 'sfquotelanding_life_root' },
		{ value: 'medicare', text: 'Medicare SF QLP', key: 'sfquotelanding_medicare_root' },
		{ value: 'pet', text: 'Pet SF QLP', key: 'sfquotelanding_pet_root' },
		{ value: 'renters', text: 'Renters SF QLP', key: 'sfquotelanding_renters_root' },
	];

	const options = filterLandingPageOptions(allOptions);

	if (!['parked', 'old_parked'].includes(domain.value?.product_type)) {
		options.unshift({ value: null, text: 'Homepage' });
	}

	return options;
});

function filterLandingPageOptions(allOptions) {
	return allOptions.filter((option, index, array) => {
		const route = routes.value.find(route => route.unique_key === option.key);
		if (route && route.unique_key === 'mxoffice2') {
			const office2 = routes.value.find(route => route.unique_key === 'mxoffice2');
			const location = office2.paths[0].split('/')[1];
			array[index].value = location;
			array[index].text = `Office - ${location.replace('-', ' ').replace('2', ' 2')}`;
		}
		return !!route;
	});
}

const landingPages = computed(() => {
	if (!domain.value) {
		return [{ value: null, text: 'Homepage', key: 'root' }];
	}
	return isDomainM2(domain?.value) ? m2LandingPages.value : m1LandingPages.value;
});
const agentStore = useAgentStore();
const { agent } = storeToRefs(agentStore);

const agentCodePlusState = computed(() => agent.value.state_code + agent.value.agent_code);
const stateCodePlusCode = computed(() => agent.value.state + ' Code');

const selectedNumberString = computed(() => (code.value || '1').padStart(3, '0'));
const fullUrlString = computed(() => {
	const domainString = domain.value?.domain_name ?? '[ One of your domains ]';
	const landingPageString = landingPage.value?.value
		? `${landingPage.value.value.toLowerCase()}/`
		: '';
	const trafficSourceString = trafficSource.value?.value.toLowerCase() ?? '[ Traffic Source ]';

	return `https://${domainString}/${landingPageString}?cmpid=${agentCodePlusState.value}${trafficSourceString}${selectedNumberString.value}`;
});

async function copyToClipboard() {
	displaySnackbar.value = false;
	await nextTick();
	displaySnackbar.value = true;

	if (!navigator.clipboard) {
		copiedLinkMessage.value = "Unable to access your browser's clipboard.";
		copiedLinkType.value = 'error';
		return;
	}

	navigator.clipboard.writeText(fullUrlString.value).then(
		() => {
			copiedLinkMessage.value = 'Copied to your Clipboard!';
			copiedLinkType.value = 'success';
		},
		() => {
			copiedLinkMessage.value = 'Unable to copy to your Clipboard.';
			copiedLinkType.value = 'error';
		}
	);
}

function isDomainM2(domain) {
	return domain?.product_type.toLowerCase() === 'm2';
}

onMounted(async () => {
	await domainStore.ensureDomains();
});

watch(
	() => domain.value,
	async () => {
		const allRoutes = await domainStore.getRoutes(domain?.value.domain_name);
		routes.value = allRoutes.filter(route => !route.disabled);
		landingPage.value = landingPages?.value[0];
	}
);
</script>
