<template>
	<div :class="{ '': sublist === unique_key }" class="flex flex-col rounded-lg bg-gray-700">
		<button
			class="z-10 flex items-center justify-between rounded-lg px-4 py-2 outline-none transition hover:bg-gray-600 focus:ring-2 focus:ring-orange-500/70"
			:disabled="$route.params.page === unique_key"
			:aria-expanded="`${sublist === unique_key || $route.params.page === unique_key}`"
			:aria-controls="`${unique_key}-sidebar-sub-group`"
			@click="toggleSublist()"
		>
			<span class="flex flex-col items-start">
				<span class="whitespace-nowrap font-semibold uppercase tracking-wider">{{
					editor.label.replace('LOB', '')
				}}</span>
				<span class="whitespace-nowrap text-xs">{{ paths[0] }}</span>
			</span>

			<span class="flex items-center justify-end space-x-2">
				<RouteToggle
					v-if="componentKeys.includes('route-disable')"
					:unique_key="unique_key"
					:disabled="disabled"
					:a11y-label="`Toggle route ${editor.label} ${disabled ? 'on' : 'off'}`"
				/>
				<FAIcon
					v-if="componentList.length"
					size="1x"
					icon="angle-down"
					class="text-white duration-200"
					:class="{ 'rotate-180': sublist === unique_key }"
				/>
			</span>
		</button>
		<BaseExpand>
			<ul
				v-if="sublist === unique_key || $route.params.page === unique_key"
				:id="`${unique_key}-sidebar-sub-group`"
				class="flex flex-col gap-2 rounded-b-lg !px-2"
			>
				<SidebarLink
					v-for="component in componentList"
					:key="component.path"
					indent="md"
					:label="component.label"
					:icon="component.icon"
					:disabled="editorStore.initializing"
					:exact="false"
					:to="{
						name: 'editor',
						params: { page: unique_key, editor: component.path },
					}"
					class="first:mt-2 last:mb-2"
				/>
			</ul>
		</BaseExpand>
	</div>
</template>
<script setup>
import { ref, computed } from 'vue';

import useEditorStore from '@/stores/editor';

import { EditorViews } from '@/views/MXEditor/views';

import SidebarLink from '@/components/navigation/SidebarLink';
import RouteToggle from '@/components/navigation/RouteToggle';
import BaseExpand from '@/components/ui/BaseExpand.vue';

const props = defineProps({
	paths: { type: Array, required: true },
	icon: { type: String, default: undefined },
	unique_key: { type: String, default: undefined },
	disabled: { type: Boolean, default: false },
	can_disable: { type: Boolean, default: true },
	route_class: { type: String, default: undefined },
	context: { type: [Object, Array], required: true },
	editor: { type: Object, required: true },
	domainName: { type: String, default: undefined },
});

const sublist = ref(false);

const editorStore = useEditorStore();
const componentList = computed(() => {
	return props.editor.components
		?.filter(c => c.group_level !== true)
		.reduce((a, { key, ...rest }) => {
			const ev = EditorViews.find(({ path }) => path === key);
			// only grab components that have a match in the EditorViews
			if (ev) {
				a.push({ ...ev, ...rest });
			}
			return a;
		}, [])
		.sort((a, b) => a.label > b.label);
});

function toggleSublist() {
	sublist.value = sublist.value === props.unique_key ? false : props.unique_key;
}

const componentKeys = computed(() => props.editor.components?.map(c => c.key) || []);
</script>

<style>
.fade-enter-active {
	@apply duration-200 ease-out;
}
.fade-leave-active {
	@apply opacity-0 duration-100 ease-in;
}
.fade-enter {
	@apply opacity-0;
}
</style>
