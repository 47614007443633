<template>
	<component :is="componentToShow" v-bind="$props" />
</template>

<script setup>
import { computed } from 'vue';

import Default from '@/components/notifications/DefaultNotification';
import NewDomain from '@/components/notifications/NewDomain';
import NewTransfer from '@/components/notifications/NewTransfer';
import DashM2Ad from '@/components/notifications/DashM2Ad';
import M1Enabled from '@/components/notifications/M1Enabled';
import M2Enabled from '@/components/notifications/M2Enabled';
import QLPUpgrade from '@/components/notifications/QLPUpgrade';

const props = defineProps({
	body: { type: String, required: true },
	component: { type: String, required: true },
	created: { type: String, required: true },
	dashboard_only: { type: Boolean, required: true },
	dismissed: { type: [Boolean, String], default: null },
	pk: { type: Number, required: true },
	tag: { type: String, required: true },
	urgency: { type: String, required: true },
	persistant: { type: Boolean, required: true },
});

const componentToShow = computed(() => {
	switch (props.component) {
		case 'new_domain':
			return NewDomain;
		case 'new_transfer':
			return NewTransfer;
		case 'm1ad':
			return DashM2Ad;
		case 'm1_enabled':
			return M1Enabled;
		case 'm2ad':
			return DashM2Ad;
		case 'm2_enabled':
			return M2Enabled;
		case 'qlp_upgrade':
			return QLPUpgrade;
		default:
			break;
	}
	return Default;
});
</script>
