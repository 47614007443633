<template>
	<div class="flex w-full flex-col justify-start gap-2 pt-4" :class="{ dark: dark }">
		<div ref="autocompleteEl" class="relative text-gray-900 dark:text-white">
			<div
				class="h-min w-full border-2 border-solid border-gray-900 py-2 pl-9 pr-12 transition-all dark:border-white"
				:class="[
					showResultsContainer ? 'rounded-t-lg' : 'rounded-lg',
					{
						'focus-within:border-orange-500 dark:focus-within:border-orange-200':
							currentlyFocusedEl === searchInputEl,
					},
				]"
			>
				<input
					id="search"
					ref="searchInputEl"
					v-model="searchInputText"
					autocomplete="off"
					:placeholder="placeholder"
					type="text"
					class="peer flex w-full cursor-text border-0 !p-0 outline-none placeholder:transition-opacity focus:ring-0 focus:placeholder:opacity-100 dark:placeholder-gray-200"
					:class="{
						'placeholder:opacity-0': !selections.length,
					}"
					role="combobox"
					aria-controls="results"
					:aria-expanded="showResultsContainer"
					aria-autocomplete="list"
					v-bind="{
						'aria-label': ariaLabel,
						'aria-describedby': ariaDescribedby,
					}"
					@keydown.down.prevent="focusResult(0)"
				/>
				<!-- sorry this is after the input but it has to be for 'peer-focus:' to work -->
				<FAIcon
					icon="fa-magnifying-glass"
					class="absolute left-3 top-1/2 -translate-y-1/2 transition-colors peer-focus:text-orange-500 dark:peer-focus:text-orange-200"
				/>
				<label
					for="search"
					:class="[
						{
							'peer-placeholder-shown:left-9 peer-placeholder-shown:top-[22.5%] peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 dark:peer-placeholder-shown:text-gray-200':
								!selections.length,
						},
					]"
					class="pointer-events-none absolute -top-5 left-1 text-sm transition-all duration-300 ease-in-out peer-focus:-top-5 peer-focus:left-1 peer-focus:text-sm peer-focus:text-orange-600 dark:peer-focus:text-orange-200"
				>
					{{ label }}
				</label>
				<button
					v-if="(searchInputText && clearable) || (selections.length && multiple)"
					class="absolute right-3 top-1/2 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full outline-none ring-offset-2 transition focus:bg-gray-200 focus:ring-2 focus:ring-gray-800 dark:focus:bg-gray-600 dark:focus:ring-white dark:focus:ring-offset-gray-500"
					@click="clearAutocomplete"
				>
					<FAIcon icon="fa-xmark" size="lg" />
				</button>
				<div
					v-if="showResultsContainer"
					id="results"
					ref="resultsEl"
					class="absolute left-0 top-full z-50 flex max-h-96 w-full flex-col gap-1 overflow-clip overflow-y-scroll !rounded-b-lg border-2 border-t-0 border-gray-300 bg-gray-200 p-2 drop-shadow-xl dark:bg-gray-500"
					role="listbox"
				>
					<ProgressBar v-if="loading" class="py-2" />
					<button
						v-for="(result, i) in results"
						v-else-if="results.length > 0"
						:key="i"
						class="group rounded-lg bg-white p-2 !no-underline outline-none transition-colors hover:bg-orange-100 hover:text-orange-700 focus:bg-orange-100 focus:text-orange-700 dark:bg-gray-600 dark:hover:bg-gray-100 dark:hover:text-gray-900 dark:focus:bg-gray-100 dark:focus:text-gray-900"
						role="option"
						:aria-selected="`${selections.includes(result)}`"
						@keydown.up.prevent="focusResult(i - 1)"
						@keydown.down.prevent="focusResult(i + 1)"
						@click.prevent="resultClicked(result)"
					>
						<slot name="item" v-bind="result">
							<span class="block text-left text-sm font-semibold">{{ result }}</span>
						</slot>
					</button>
					<slot v-else name="no-data"
						><span class="rounded-lg bg-white py-2 pl-3 font-bold"
							>No results</span
						></slot
					>
				</div>
			</div>
		</div>
		<div v-if="multiple" class="flex w-full flex-wrap content-start gap-2">
			<template v-for="selection in selections">
				<slot name="selection" v-bind="{ selection }">
					{{ selection }}
				</slot>
			</template>
		</div>
	</div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import { useActiveElement } from '@vueuse/core';

const emit = defineEmits(['update:search-input', 'change', 'click:clear', 'update:value']);

const props = defineProps({
	ariaAutocomplete: {
		type: String,
		required: false,
		default: 'list',
	},
	ariaControls: {
		type: String,
		required: false,
		default: 'search',
	},
	ariaDescribedby: {
		type: String,
		required: false,
		default: 'search',
	},
	ariaExpanded: {
		type: Boolean,
		required: false,
		default: false,
	},
	ariaLabel: {
		type: String,
		required: false,
		default: 'Search',
	},
	autofocus: {
		type: Boolean,
		required: false,
		default: false,
	},
	clearable: {
		type: Boolean,
		required: false,
		default: false,
	},
	closeOnContentClick: {
		type: Boolean,
		required: false,
		default: false,
	},
	dark: {
		type: Boolean,
		required: false,
		default: false,
	},
	hideSelected: {
		type: Boolean,
		required: false,
		default: false,
	},
	items: {
		type: Array,
		required: true,
	},
	label: {
		type: String,
		required: true,
	},
	loading: {
		type: Boolean,
		required: false,
		default: false,
	},
	multiple: {
		type: Boolean,
		required: false,
		default: false,
	},
	noFilter: {
		type: Boolean,
		required: false,
		default: false,
	},
	placeholder: {
		type: String,
		required: false,
		default: 'Search',
	},
	value: {
		type: [Object, Array],
		required: false,
		default: null,
	},
});

const searchInputText = ref('');
const selections = ref([]);

const autocompleteEl = ref(null);
const searchInputEl = ref(null);
const resultsEl = ref(null);
const currentlyFocusedEl = useActiveElement();

const results = computed(() => {
	if ((props.noFilter || !searchInputText.value) && props.hideSelected) {
		return props.items.filter(item => !selections.value.includes(item));
	}

	if (props.noFilter && !props.hideSelected) {
		return props.items;
	}

	if (!props.noFilter && props.hideSelected && searchInputText.value) {
		return props.items.filter(
			item =>
				!selections.value.includes(item) &&
				item.toLowerCase().includes(searchInputText.value.toLowerCase())
		);
	}

	return props.items.filter(item =>
		item.toLowerCase().includes(searchInputText.value.toLowerCase())
	);
});

const focusIsWithinAutocomplete = computed(() => {
	return autocompleteEl.value?.contains(currentlyFocusedEl.value);
});

const showResultsContainer = computed(
	() => focusIsWithinAutocomplete.value && (searchInputText.value || results.value.length > 0)
);

function focusResult(i) {
	if (i < 0) {
		searchInputEl.value.focus();
		return;
	}
	if (i < results.value.length) {
		resultsEl.value.querySelectorAll('button')[i].focus();
	}
}

function resultClicked(result) {
	emit('change', result);
	if (props.multiple) {
		searchInputText.value = '';
		selections.value = [...selections.value, result];
	}
	if (props.closeOnContentClick) {
		setTimeout(() => {
			currentlyFocusedEl.value.blur();
		}, 1);
	}
}

function clearAutocomplete() {
	searchInputText.value = '';
	selections.value = [];
	searchInputEl.value.focus();
	emit('click:clear');
}

watch(
	() => props.value,
	() => {
		if (props.multiple && props.value !== selections.value) {
			selections.value = props.value;
			searchInputEl.value?.focus();
		}
	},
	{ immediate: true }
);

watch(focusIsWithinAutocomplete, newVal => {
	if (!newVal) searchInputText.value = '';
});

watch(searchInputText, () => {
	emit('update:search-input', searchInputText.value);
});

watch(selections, () => {
	emit('update:value', selections.value);
});

onMounted(() => {
	if (props.autofocus) {
		setTimeout(() => {
			searchInputEl.value.focus();
		}, 1);
	}
});

defineExpose({
	searchInputText,
	results,
	selections,
	resultClicked,
});
</script>
