<template>
	<ValidationObserver
		v-slot="{ pending, valid, changed }"
		tag="section"
		role="application"
		:aria-label="`${name} Text Editor`"
	>
		<p v-if="showDisclaimer">
			<PersonalInfoDisclaimer :id="disclaimerId" />
		</p>
		<h4
			:id="editorLabelId"
			class="mt-4 text-xl font-bold leading-none text-sf-charcoal first:!mt-0"
		>
			{{ name }} Text Editor
		</h4>
		<BaseAlert v-if="savingChanges" type="info">Saving in progress...</BaseAlert>
		<ValidationProvider
			ref="provider"
			v-slot="{ errors }"
			:name="`${name} Text Editor`"
			tag="div"
			class="flex flex-col items-stretch gap-2"
			:rules="{ compliance: compliance.parsedResult, required }"
		>
			<TextEditor
				v-if="type === 'richtext'"
				ref="tiptapTextEditor"
				:aria-labelledby="editorLabelId"
				:aria-describedby="disclaimerId"
				role="document"
				tabindex="0"
				:value.sync="localText"
				:height="height"
				:max-character-count="maxCharacterCount"
				:placeholder="placeholder"
				:show-menu="showMenu"
				:warnings="warnings"
				@new-character-count="localCount = $event"
				@update:value="debouncedValidate"
			/>
			<TextFieldInput
				v-else-if="type === 'text'"
				autofocus
				:aria-labelledby="editorLabelId"
				:aria-describedby="disclaimerId"
				role="document"
				:value.sync="localText"
				:label="name"
				class="mt-4 grow"
				v-bind="{ maxCharacterCount }"
				@update:value="debouncedValidate"
			/>
			<div class="flex justify-end gap-2">
				<BaseButton
					v-if="showConfirmButton"
					outline
					dense
					:disabled="!valid || pending"
					class="inline-block"
					@click="$emit('update:value', localText)"
				>
					Confirm
				</BaseButton>
				<BaseButton
					v-if="!hideCancel"
					type="button"
					outline
					dense
					color="gray"
					class="ml-4 inline-block"
					@click="$emit('update:value', originalText)"
				>
					Cancel
				</BaseButton>
			</div>

			<div aria-live="assertive" class="my-2 space-y-2">
				<BaseAlert v-if="pending" dense outlined type="info">
					<ProgressBar class="h-auto items-start" text="Checking compliance..." />
				</BaseAlert>

				<BaseAlert v-if="spellingStatus === 'failed'" dense outlined text type="warning">
					There may be misspelled words, please check before saving.
				</BaseAlert>

				<BaseAlert v-if="errors.length > 0" type="error" dense>
					<div v-if="invalidTerms.length > 0 || generalViolations.length > 0">
						<div v-if="invalidTerms.length > 0">
							<p class="mb-0">
								Some of this text is not compliant. Please remove or reword the
								following:
							</p>

							<ul
								v-for="(term, index) in invalidTerms"
								:key="`${term.replaceAll(' ', '_')}_${index}`"
								class="list-inside list-disc"
							>
								<li>{{ term }}</li>
							</ul>
						</div>
						<div v-if="generalViolations.length > 0">
							<p class="mb-0">
								This text is not compliant. Please address the following:
							</p>

							<ul
								v-for="(msg, index) in generalViolations"
								:key="`${msg.replaceAll(' ', '_')}_${index}`"
								class="list-inside list-disc"
							>
								<li>{{ msg }}</li>
							</ul>
						</div>
					</div>
					<template v-else>{{ errors[0] }}</template>
				</BaseAlert>

				<BaseAlert v-if="changed && valid && !pending" dense type="success">
					This text is compliant.
				</BaseAlert>

				<BaseAlert v-if="complianceStatus === 'error'" dense type="error">
					<b>Our apologies, there was an error while checking compliance.</b>
					<br />
					Support has been notified, but you can't make text edits at the moment -- please
					try again later. Thanks for your patience!
				</BaseAlert>

				<BaseAlert v-if="maxCharacterCount && localCount > maxCharacterCount" type="warn">
					<slot name="exceeded-character-count-message"></slot>
				</BaseAlert>
			</div>
		</ValidationProvider>
	</ValidationObserver>
</template>

<script setup>
import TextEditor from '@mirus/tiptap-editor';
import { computed, onUnmounted, watchEffect, ref, onMounted } from 'vue';

import PersonalInfoDisclaimer from '@/components/MXEditor/PersonalInfoDisclaimer';

import useEditorChangesStore from '@/stores/editorChanges';
import { storeToRefs } from 'pinia';

import BaseAlert from '@/components/ui/BaseAlert';
import ProgressBar from '@/components/ui/ProgressBar';
import TextFieldInput from '@/components/ui/TextFieldInput';
import { useCompliance } from '@/composables/useCompliance';
import BaseButton from '@/components/ui/BaseButton';
import { useDebounceFn } from '@vueuse/core';

/*
   IMPORTANT: Do not emit a value that has not been checked for compliance.
   Components that use this checker will assume that only compliant results are emitted.
 */
const emit = defineEmits(['update:value', 'update:compliance-response', 'update:compliance-text']);

const props = defineProps({
	name: { type: String, required: true, default: 'Text Editor' },
	value: { type: String, required: true },
	placeholder: { type: String, default: 'Enter text here' },
	showMenu: { type: Boolean, default: true },
	showDisclaimer: { type: Boolean, default: true },
	maxCharacterCount: { type: Number, default: null },
	height: { type: String, default: '300px' },
	required: { type: Boolean, default: true },
	allowedTerms: { type: Array, default: () => [] },
	confirmChanges: { type: Boolean, default: false },
	hideCancel: { type: Boolean, default: false },
	domain: { type: String, default: null },
	productType: { type: String, default: null },

	type: {
		type: String,
		default: 'richtext',
		validator: value => {
			const validTypes = ['richtext', 'text'];
			const styles = [
				'color: green',
				'background: yellow',
				'font-size: 15px',
				'padding: 2px',
			].join(';');
			if (validTypes.includes(value)) {
				return true;
			} else {
				// eslint-disable
				console.group('ComplianceChecker');
				console.error(`ComplianceChecker ERROR: invalid type: %c${value}`, styles);
				console.warn(
					`Valid ComplianceChecker types include:\n\n%c - ${validTypes.join('\n - ')}`,
					styles
				);
				console.groupEnd();
				// eslint-enable
				return false;
			}
		},
	},
});

const compliance = useCompliance();
const editorChangesStore = useEditorChangesStore();
const { savingChanges } = storeToRefs(editorChangesStore);

const provider = ref(null);
const originalText = ref(null);
const localText = ref(null);
const localCount = ref(null);
const message = ref(null);
const showConfirmButton = ref(false);

const disclaimerId = computed(() => `${props.name.replaceAll(' ', '')}InfoDisclaimer`);
const editorLabelId = computed(() => `${props.name.replaceAll(' ', '')}TextEditorLabel`);

const warnings = computed(() => {
	const {
		collapsedViolations = [],
		sanitations = [],
		spellingMistakes = [],
	} = compliance.parsedResult.value ?? {};
	return [...collapsedViolations, ...sanitations, ...spellingMistakes];
});

const invalidTerms = computed(() => {
	const { collapsedViolations = [], sanitations = [] } = compliance.parsedResult.value ?? {};

	return [...collapsedViolations, ...sanitations].map(({ value }) => value);
});

const generalViolations = computed(() => {
	const { generalViolations = [] } = compliance.parsedResult.value ?? {};
	return generalViolations;
});

const complianceStatus = computed(() => {
	if (compliance.parsedResult.value) {
		const { isSanitized, isCompliant } = compliance.parsedResult.value ?? {};
		return isCompliant && isSanitized ? 'passed' : 'failed';
	} else {
		return 'init';
	}
});

const spellingStatus = computed(() => {
	if (compliance.parsedResult.value?.spellingMistakes) {
		return compliance.parsedResult.value.spellingMistakes?.length < 1 ? 'passed' : 'failed';
	} else {
		return 'unchecked';
	}
});

async function validate() {
	compliance.clearCompliance();
	/*
    manually set the validation flags to prevent saving while compliance results are pending.
    This was in the custom validation rule at one point but the component needs access to the results.
    Without these flags the validation provider will not know about changes until after the api call finishes.
    */
	provider.value?.setFlags({ changed: true, pending: true });
	const localTextToCheck = localText.value;
	const isEmptyString = localTextToCheck === '';

	if (isEmptyString) {
		compliance.clearCompliance();
		// validation will fail if props.required is true
		await provider.value?.validate('');
		return;
	}

	try {
		await compliance.checkCompliance({
			text: localTextToCheck,
			allowedTerms: props.allowedTerms,
			domain: props.domain,
			productType: props.productType,
		});
		emit('update:compliance-response', compliance.lastRawResponse.value);
		emit('update:compliance-text', localTextToCheck);

		message.value = `Some of this text is not compliant. Please remove or reword the following:`;
		await provider.value?.validate?.(localTextToCheck);

		if (provider.value?.flags?.valid) {
			if (props.confirmChanges) {
				showConfirmButton.value = true;
			} else {
				emit('update:value', localText.value);
			}
		}

		return Promise.resolve();
	} catch (error) {
		console.error(error);
		message.value = 'The compliance check failed. Please try again.';
		return Promise.reject();
	}
}

const debouncedValidate = useDebounceFn(validate, 1000);

watchEffect(() => {
	if (originalText.value !== props.value) {
		localText.value = props.value;
		originalText.value = props.value;
	}
});
onMounted(() => {
	localText.value = props.value;
	originalText.value = props.value;
	provider.value?.reset?.();
});
onUnmounted(() => {
	compliance.clearCompliance();
});
</script>

<style lang="scss">
.ProseMirror {
	height: 100%;
	overflow: auto;
}

.tiptap-editor {
	ul {
		list-style: disc;
	}
}
</style>
