<template>
	<dl class="flex w-full flex-col gap-2 !rounded-lg bg-gray-100 p-2">
		<div v-for="(item, i) in items" :key="i">
			<dt class="block w-full">
				<button
					class="flex w-full items-center justify-between rounded-md bg-white p-2 pr-4 text-left text-lg font-medium transition-colors hover:bg-orange-100"
					:class="[{ 'cursor-not-allowed': item.disabled }]"
					:disabled="item.disabled"
					@click="toggleContent(i)"
				>
					<slot name="header" v-bind="item">
						<span class="sr-only">
							{{ activeContent === i ? 'Hide' : 'Show' }} content for </span
						>{{ item.label }}
					</slot>
					<FAIcon
						v-if="!item.disabled"
						icon="chevron-down"
						class="transition-transform"
						:class="{ 'rotate-180': activeContent === i }"
					/>
				</button>
			</dt>
			<BaseExpand>
				<dd v-if="activeContent === i" class="mt-2 overflow-clip rounded-md bg-white">
					<slot name="content" v-bind="item" />
				</dd>
			</BaseExpand>
		</div>
	</dl>
</template>

<script setup>
import { ref } from 'vue';

import BaseExpand from '@/components/ui/BaseExpand';

defineProps({
	items: { type: Array, required: true },
});
const activeContent = ref(null);

function toggleContent(panel) {
	activeContent.value = activeContent.value === panel ? null : panel;
}
</script>
