<template>
	<ProgressBar v-if="loading" text="Loading domain info..." />

	<div v-else class="flex flex-col gap-2">
		<div
			v-if="domain.product_type === 'sf_microsite'"
			class="grid grid-cols-1 gap-4 rounded-md bg-white p-4 shadow-inner shadow-gray-300 dark:bg-gray-800 dark:shadow-gray-900 xl:grid-cols-4"
		>
			<BaseAlert v-if="agent.is_spanish_disabled_for_co" class="xl:col-span-4">
				Please note that in compliance with Colorado House Bill 23-1004, Spanish domain
				content will not be displayed to Colorado users. If you previously selected Spanish
				for your proxy domain, the language has been automatically set to English.
			</BaseAlert>
			<BaseAlert
				v-if="!languageChoiceValid && !agent.is_spanish_disabled_for_co"
				type="warn"
				class="xl:col-span-4"
			>
				You have selected Spanish, but
				<span v-if="domain.spanish_unavailable_date">
					as of
					<span
						v-html="format(parseISO(domain.spanish_unavailable_date), 'MMM do, yyyy')"
					/>
				</span>
				our system is no longer detecting Spanish as an available Language option on
				<HelpMicrosite />. Languages displayed on your microsite take into consideration all
				languages designated in the profiles (Agent and Team Members) of your office. In
				order to add or remove languages, you will need to submit a request to the Associate
				Register (AR) using the appropriate link below:
				<ul class="list-inside list-disc">
					<li><HelpARAgent /></li>
					<li><HelpARTeam /></li>
				</ul>
				Within 48 hours of Spanish language being available on your microsite, this domain
				will again display in Spanish.
			</BaseAlert>
			<div
				class="flex flex-col gap-4 rounded-md border-2 border-gray-300 p-4 dark:border-gray-600 xl:col-span-3 2xl:flex-row"
			>
				<div class="w-full">
					<h5 class="text-xl font-bold">Meta Description:</h5>
					<p>
						Meta descriptions are HTML attributes that provide concise explanations of
						the contents of web pages. Meta descriptions are commonly used on search
						engine result pages (SERPs) to display preview snippets for a given page.
					</p>
					<figure>
						<img class="img-responsive mx-auto !rounded" :src="meta_image" alt="" />
						<figcaption class="mt-1 text-center text-xs text-black">
							Meta Description highlighted in search engine results
						</figcaption>
					</figure>
				</div>
				<div class="flex w-full flex-col gap-2">
					<BaseButtonGroup
						:value.sync="useCustomMeta"
						:buttons="[
							{ value: false, text: 'Default' },
							{ value: true, text: 'Custom' },
						]"
						background="light"
					/>
					<div v-if="useCustomMeta">
						<ComplianceChecker
							:label="`Selected: ${useCustomMeta ? 'CUSTOM' : 'DEFAULT'} meta text`"
							:value.sync="customMeta"
							type="text"
							name="Custom Meta Description"
							class="!rounded-md bg-white p-2"
							height="100px"
							:max-character-count="160"
							:placeholder="defaultMetaText"
							:show-menu="false"
							:allowed-terms="[phone]"
							:domain="domain.domain_name"
						>
							<template #exceeded-character-count-message>
								The recommended maximum length for a meta description has been
								reduced to 160 characters. Your custom meta description is currently
								longer than that limit. While it will still work on your domain;
								it's highly recommended that you reduce the length.
							</template>
						</ComplianceChecker>
						<p class="!m-0 mt-6">
							When writing a custom meta description, utilize these points:
						</p>
						<ul class="mt-3 list-inside list-disc">
							<li>
								Users typically search by product or locale. Note your location and
								up to three specific insurance products
							</li>
							<li>Keep the total length to 150-160 total characters</li>
							<li>
								Google truncates quotes. If you need quotation marks, use single
								quotes (') rather than double quotes
							</li>
							<li>Avoid other non-alphanumeric characters (A-Z, 0-9)</li>
							<li>
								You may add your phone number in the following format: {{ phone }}.
							</li>
						</ul>
					</div>
					<BaseTextArea
						name="customMetaTextarea"
						:label="`Selected: ${useCustomMeta ? 'CUSTOM' : 'DEFAULT'} meta text`"
						:placeholder="defaultMetaText"
						class="mt-6"
						show-placeholder
						disabled
					/>
				</div>
			</div>
			<div class="flex flex-col justify-between gap-4">
				<div
					class="flex grow-0 flex-col gap-1 rounded-md border-2 border-gray-300 p-4 pt-3 dark:border-gray-600"
				>
					<h5 class="text-xl font-bold">Language:</h5>
					<BaseSelect
						id="languageChoice"
						:value.sync="languageChoice"
						:items="languageOptions"
						label="Language"
						dark
						pill
					/>
				</div>
				<div
					class="flex grow-0 flex-col gap-1 rounded-md border-2 border-gray-300 p-4 pt-3 dark:border-gray-600"
				>
					<h5 class="text-xl font-bold">Canonical tag:</h5>
					<BaseSelect
						id="removeCanonicalChoice"
						:value.sync="removeCanonicalChoice"
						:items="canonical_choices"
						label="Remove/keep canonical tag"
						dark
						pill
					/>
				</div>
				<div class="flex items-end justify-end">
					<BaseButton color="primary" :disabled="!changed" @click="onSaveOptions">
						Save Changes
					</BaseButton>
				</div>
			</div>
		</div>
		<!--
		Secure Parked Domain Section (parked and old_parked)
		-->
		<div
			v-else-if="['parked', 'old_parked'].includes(domain.product_type)"
			class="flex flex-wrap items-end gap-2"
		>
			<h5 class="w-full text-xl font-bold">
				Redirect To:
				<br />
				<span class="text-sm font-normal">
					Traffic is redirected to a domain of your choice
				</span>
			</h5>

			<BaseSelect
				id="parkedTarget"
				:items="parkedChoices"
				:value.sync="parkedTarget"
				autowidth
				class="!w-auto shrink grow-0"
				label="Select Redirect Target"
			/>
			<BaseButton
				:disabled="!changed"
				class="w-auto shrink grow-0"
				color="primary"
				@click="onSaveOptions"
			>
				Save
			</BaseButton>
		</div>

		<!-- Upgrade/downgrade capabilities -->
		<DomainProductTypeActions
			:domain="domain"
			:email-count="filteredEmails.length"
			:parked-target-count="parkedTargets.length"
		/>
	</div>
</template>
<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { format, parseISO } from 'date-fns';
import { storeToRefs } from 'pinia';

import meta_image from '@/assets/images/meta-description.jpg';

import { phoneFormat } from '@/utils/MXEditor/filters.js';

import { useApi } from '@/composables/useApi';

import useDomainsStore from '@/stores/agent/agent-domains';
import useEmailStore from '@/stores/agent/agent-emails';
import useAgentStore from '@/stores/agent/agent';
import useEditorStore from '@/stores/editor';

import ProgressBar from '@/components/ui/ProgressBar';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButtonGroup from '@/components/ui/BaseButtonGroup.vue';
import BaseTextArea from '@/components/ui/BaseTextArea.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';

import DomainProductTypeActions from '@/components/domains/DomainProductTypeActions.vue';

import ComplianceChecker from '@/components/MXEditor/ComplianceChecker.vue';
import HelpARAgent from '@/components/MXEditor/help/HelpARAgent.vue';
import HelpARTeam from '@/components/MXEditor/help/HelpARTeam.vue';
import HelpMicrosite from '@/components/MXEditor/help/HelpMicrosite.vue';

const emailStore = useEmailStore();
const domainStore = useDomainsStore();
const agentStore = useAgentStore();
const editorStore = useEditorStore();

const languages = {
	english: { text: 'English', value: 'en' },
	spanish: { text: 'Spanish', value: 'es' },
};

const canonical_choices = [
	{ text: 'Keep it', value: false },
	{ text: 'Remove it', value: true },
];

const props = defineProps({
	domain: {
		type: Object,
		required: true,
	},
});

const languageChoice = ref(null);
const removeCanonicalChoice = ref(null);
const saving = ref(false);
const useCustomMeta = ref(false);
const customMeta = ref(null);
const parkedTarget = ref(null);

const { agent } = storeToRefs(agentStore);
const { parkedTargets } = storeToRefs(domainStore);
const { emails } = storeToRefs(emailStore);
const { preferredPhone } = storeToRefs(editorStore);

const defaultMetaText = computed(() => {
	const { first_name, last_name, city, state, phone_number } = agent.value;
	return `State Farm Agent ${first_name} ${last_name} in ${city}, ${state} - We offer Auto, Home, Life, and Renters insurance.  Call us at ${phoneFormat(
		phone_number
	)} to learn more.`;
});

const phone = computed(() => preferredPhone.value?.pretty);

const filteredEmails = computed(() =>
	emails.value?.filter(item => item.domain_name === props.domain.domain_name)
);
const loading = computed(() => saving.value || !filteredEmails.value);
const parkedChoices = computed(() =>
	parkedTargets.value
		.filter(item => item.domain_name !== props.domain.domain_name)
		.map(item => {
			return { text: item.domain_name, value: item.domain_name };
		})
);

const languageOptions = computed(() => {
	return props.domain.allowed_languages.map(item => languages[item]).filter(item => !!item);
});

const languageChoiceValid = computed(() => {
	return props.domain.allowed_languages
		.map(item => languages[item]?.value ?? undefined)
		.filter(item => !!item)
		.includes(props.domain.options.language);
});

const changed = computed(() => {
	if (removeCanonicalChoice.value !== props.domain.options.remove_canonical_link) {
		return true;
	}
	if (languageChoice.value !== props.domain.options.language) {
		return true;
	}
	if (customMeta.value !== (props.domain.options.meta_description || defaultMetaText.value)) {
		return true;
	}
	if (parkedTarget.value !== props.domain.target) {
		return true;
	}
	if (current_meta.value !== (props.domain.options.meta_description || defaultMetaText.value)) {
		return true;
	}
	return false;
});

const current_meta = computed(() => {
	return useCustomMeta.value ? customMeta.value : defaultMetaText.value;
});

onMounted(async () => {
	removeCanonicalChoice.value = props.domain.options.remove_canonical_link;
	languageChoice.value = props.domain.options.language;
	customMeta.value = props.domain.options.meta_description || defaultMetaText.value;
	useCustomMeta.value = customMeta.value !== defaultMetaText.value;
	parkedTarget.value = props.domain.target;
	await nextTick();
	await editorStore.updateConfig(props.domain.domain_name);
	await domainStore.ensureDomains();
	await emailStore.ensureEmails();
});

async function onSaveOptions() {
	saving.value = true;
	await useApi(`domains/${props.domain.domain_name}/`, {
		v3Agent: true,
		message: 'There was an issue saving your domain name! Please try again later.',
	}).put({
		product_type: props.domain.product_type,
		parked_target: parkedTarget.value,
		language: languageChoice.value,
		remove_canonical_link: removeCanonicalChoice.value,
		meta_description: current_meta.value,
	});

	await domainStore.getDomains();
	saving.value = false;
}
</script>
<style scoped></style>
