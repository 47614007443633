<template>
	<div v-if="isMirus || isDemoUser">
		<p>
			You have access to the editor for the following domains<span v-if="isMirus">
				(Mirus & demo users Only)</span
			>:
		</p>
		<ul class="mb-4 pl-0">
			<li v-for="domain in demoDomains" :key="domain" class="flex items-center">
				<AppLink
					color="gray"
					:to="{
						name: 'editorIndex',
						params: { feature: 'home', agentID: '0XDEADBEEFD', domainName: domain },
					}"
					class="flex grow-0 items-center gap-2 rounded px-2 py-1 uppercase"
				>
					<FAIcon icon="pencil" />
					<span class="text-xl font-bold">{{ domain }}</span>
				</AppLink>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import useUserStore from '@/stores/user';
import useConfigStore from '@/stores/site/config';

import AppLink from '@/components/ui/AppLink.vue';

const props = defineProps({
	id: { type: String, required: true },
});

const configStore = useConfigStore();
const demoDomains = computed(() => configStore.demoDomains);
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const isMirus = computed(() => user.value?.user_type === 'mirus');
const demoUsers = computed(() => configStore.demoUsers);
const isDemoUser = computed(() => {
	return demoUsers.value?.find(({ associate_id }) => associate_id === props.id);
});
</script>

<style scoped></style>
