<template>
	<li class="rounded border-2 border-solid p-4">
		<h4 class="font-bold">{{ title }}</h4>
		<AppLink :href="url">{{ url }}</AppLink>
		<p v-if="type" class="mb-0">Type: {{ type }}</p>
		<p v-if="location" class="mb-0">Location: {{ location }}</p>
		<button
			class="rounded border-2 border-solid border-transparent border-b-blue-700 p-1 text-blue-700 hover:border-blue-700"
			@click.prevent="showDetails = !showDetails"
		>
			{{ showDetails ? 'Hide' : 'Show' }} Details
		</button>
		<template v-if="showDetails">
			<template v-for="[label, content] in Object.entries(description)">
				<h5 :key="`${content}-title`" class="font-bold capitalize">{{ label }}</h5>
				<div :key="`${content}-content`" v-html="content" />
			</template>
		</template>
	</li>
</template>
<script setup>
import AppLink from '@/components/ui/AppLink.vue';
import { ref } from 'vue';

defineProps({
	title: { type: String, required: true },
	url: { type: String, required: true },
	type: { type: String, default: undefined },
	location: { type: String, default: undefined },
	description: { type: Object, required: true },
});
const showDetails = ref(false);
</script>
