<template>
	<div class="flex flex-col gap-2">
		<div class="grid gap-2 xl:grid-cols-2 2xl:grid-cols-3">
			<template v-for="(val, key) in productTypes">
				<div v-if="key !== domain.product_type && key !== 'old_parked'" :key="key">
					<DomainTypeChange
						:title="`${getAction(key).label} to ${val.titleLong}?`"
						:disabled="isDisabled(key)"
						:action-color="getAction(key).color"
						:action-icon="getAction(key).icon"
						:action-text="`${getAction(key).label}`"
						:price="val.price"
						:options="{
							service: domain.domain_name,
							original_product_type: domain.product_type,
							future_product_type: key,
							email_count: emailCount,
						}"
						:emails="emailCount"
						@confirm="
							domainStore.setProductType(agent.associate_id, domain.domain_name, key)
						"
					>
						<template #title
							>Confirm {{ getAction(key).label }} to {{ val.titleLong }}</template
						>
						<template #description>
							<p>{{ val.description }}</p>
							<b v-if="disableForNoTarget(key)"
								>You cannot downgrade your only Secure Domain</b
							>
							<b v-if="disabledForIntern(key)">
								{{ redirectOnlyForAgentInternText }}
							</b>
							<M2DiscountList v-if="key === 'm2'" />
						</template>
					</DomainTypeChange>
				</div>
			</template>
		</div>
		<DomainTypeChange
			title="Cancel this domain?"
			action-text="Cancel Domain"
			action-color="error"
			action-icon="circle-x"
			:options="{
				service: domain.domain_name,
				original_product_type: domain.product_type,
				amount: 0,
				email_count: emailCount,
			}"
			@confirm="onCancel"
		>
			<template #description>
				<template v-if="cancelling">
					<ProgressBar class="w-full" :height="1" />
					<BaseAlert type="warn" class="mt-2">
						Canceling <code>{{ domain.domain_name }}</code
						>. Please do not leave this page.
					</BaseAlert>
				</template>
				<template v-else>
					<p class="pt-2">
						Cancelling a domain removes it from your account completely, any email
						redirects for this domain will disabled, and allows it to be purchased by
						others. Have you considered downgrading this domain to a Secure Parked
						domain instead?
					</p>
					<BaseAlert type="error">
						WARNING: If you cancel your domain and another individual or business
						purchases it, you will be unable to get it back in the future unless and
						until they release it.
					</BaseAlert>
				</template>
			</template>
		</DomainTypeChange>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';
import DomainTypeChange from '@/components/domains/DomainTypeChange.vue';
import M2DiscountList from '@/components/domains/purchase/M2DiscountList.vue';
import { productTypes, redirectOnlyForAgentInternText } from '@/constants';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import { storeToRefs } from 'pinia';
import useAgentStore from '@/stores/agent/agent';
import useDomainStore from '@/stores/agent/agent-domains';

const agentStore = useAgentStore();
const domainStore = useDomainStore();

const { agent } = storeToRefs(agentStore);

const props = defineProps({
	domain: { type: Object, required: true },
	emailCount: { type: Number, required: true },
	parkedTargetCount: { type: Number, required: true },
});
const cancelling = ref(false);

const isIntern = computed(() => agent.value?.is_agent_intern ?? false);

function disabledForIntern(productType) {
	return isIntern.value && ['m1', 'm2'].includes(productType);
}

function disableForNoTarget(productType) {
	return (
		props.parkedTargetCount <= 1 &&
		['parked', 'old_parked'].includes(productType) &&
		['m1', 'm2', 'sf_microsite'].includes(props.domain.product_type)
	);
}

function isDisabled(productType) {
	return disabledForIntern(productType) || disableForNoTarget(productType);
}

async function onCancel() {
	cancelling.value = true;
	await domainStore.cancelDomain({ domainName: props.domain.domain_name });
	cancelling.value = false;
}

function getAction(productType) {
	const p = Object.keys(productTypes);
	const diff = p.indexOf(props.domain.product_type) - p.indexOf(productType);
	if (diff > 0) {
		return { label: 'Upgrade', color: 'success', icon: 'arrow-up' };
	}
	if (diff === 0) {
		return null;
	}
	if (diff < 0) {
		return { label: 'Downgrade', color: 'warning', icon: 'arrow-down' };
	}
}
</script>
