<template>
	<div>
		<MirusOnlyBorder :dense="false">
			<BaseButton color="primary" :disabled="processingIntern" @click="toggleUpgradeDialog">
				{{ processingIntern ? 'Working...' : 'Convert to Agent Intern' }}
			</BaseButton>
		</MirusOnlyBorder>

		<BaseDialog :value.sync="showUpgradeDialog" max-width="600" alert>
			<template #header>
				<div>
					<h2 class="text-xl font-bold text-gray-900">Upgrade to Agent Intern</h2>
					<p class="!m-0 text-gray-700">
						{{ data.first_name }} {{ data.last_name }} :: {{ data.alias }}
					</p>
				</div>
			</template>

			<p class="!m-0">
				Are you sure you want to convert Staff {{ data.first_name }}
				{{ data.last_name }} into an Agent Intern?
			</p>

			<template #actions>
				<BaseButton color="primary" @click="confirmUpgrade"> Confirm </BaseButton>
				<BaseButton color="gray" @click="toggleUpgradeDialog">Never mind</BaseButton>
			</template>
		</BaseDialog>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router/composables';

import { useApi } from '@/composables/useApi';
import useAlertStore from '@/stores/alerts';

import BaseButton from '@/components/ui/BaseButton.vue';
import MirusOnlyBorder from '@/components/common/MirusOnlyBorder.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';

const props = defineProps({
	id: { type: String, required: true },
	data: { type: Object, required: true },
	employeeType: { type: String, required: true },
});

const router = useRouter();

const showUpgradeDialog = ref(false);
const processingIntern = ref(false);

function toggleUpgradeDialog() {
	showUpgradeDialog.value = !showUpgradeDialog.value;
}

async function upgrade() {
	const person = props.data;
	const employeeType = props.employeeType;

	processingIntern.value = true;

	try {
		const form = new FormData();
		form.append('alias', person.alias);
		form.append('associate_id', person.associate_id);

		const res = await useApi(`dashboard/xhr/create_intern/${employeeType}/`, {
			message: 'There was an issue upgrading your employee type! Please try again later.',
		})
			.post(form)
			.json();

		if (res.data?.value?.success) {
			return { success: res.data.value.success };
		} else {
			throw new Error(res.data.value?.success);
		}
	} catch (error) {
		const alertStore = useAlertStore();
		console.error(error);
		alertStore.addAlert({
			message: `There was an issue with making ${person.first_name} ${person.last_name} an agent intern. Please try again later.`,
		});

		throw new Error({ success: false });
	} finally {
		processingIntern.value = false;
	}
}

async function confirmUpgrade() {
	try {
		const { success } = await upgrade();
		if (success) {
			await router.push(`/agents/${props.data.associate_id}/`);
		}
	} catch {
		showUpgradeDialog.value = false;
	}
}
</script>

<style scoped></style>
