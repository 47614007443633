<template>
	<BaseExpansionList v-bind="{ items }">
		<template #header="{ value, domain, agent, override_type, date_created }">
			<div class="grid w-full grid-flow-col grid-cols-11 items-center gap-4">
				<img
					:src="value"
					alt=""
					class="border-gray bg-checkerboard col-span-2 mx-auto border-2 border-solid bg-repeat object-cover object-center md:col-span-1"
					:class="[
						['staff_avatar', 'agent_avatar'].includes(override_type)
							? 'h-16 w-16 rounded-full'
							: 'w-full rounded',
					]"
				/>
				<div class="col-span-8 md:col-span-4">
					<b>Domain: </b>
					<a
						:href="`https://${domain}`"
						target="_blank"
						class="!text-orange hover:!text-orange-700"
						@click.stop
					>
						{{ domain }}
					</a>
					<br />
					<b>Agent: </b>
					<a
						:href="`/agents/${agent.associate_id}/domains`"
						target="_blank"
						class="!text-orange hover:!text-orange-700"
						@click.stop
					>
						{{ agent?.name }}
					</a>
					({{ agent?.alias }} / {{ agent?.associate_id }})
				</div>
				<div class="col-span-8 mb-0 md:col-span-4">
					<b>Type: </b>
					<code>{{ override_type }}</code>
					<br />
					<b>
						{{ format(new Date(date_created), 'h:mma MMM d, yyyy') }}
						({{ formatDistanceToNow(new Date(date_created)) }})
					</b>
				</div>
			</div>
		</template>
		<template #content="{ agent, domain, value, previous_value, override_type, item_id }">
			<div class="p-4">
				<ProgressBar
					v-if="savingJudgment"
					class="w-full"
					:value="saveProgress"
					:max="100"
				/>

				<template v-if="savingJudgment">
					<div>Saving...</div>
				</template>

				<BannerReview
					v-if="['office_banner', 'more_info_image'].includes(override_type)"
					v-bind="{ value, previous_value, override_type }"
				/>
				<AvatarReview
					v-if="['agent_avatar', 'staff_avatar'].includes(override_type)"
					v-bind="{ agent, value, previous_value, override_type }"
				/>
				<SquareReview
					v-if="
						[
							'jobs_team_images',
							'mission_tab_images',
							'team_tab_images',
							'locations_image',
						].includes(override_type)
					"
					v-bind="{ value, previous_value, override_type }"
				/>

				<footer class="mt-4">
					<div
						v-if="!['deny', 'accept'].includes(judgement)"
						class="mb-2 flex justify-end space-x-2"
					>
						<BaseButton
							:href="`https://${domain}?editing&previewPending&nakalaconfig`"
							color="info"
							target="_blank"
							rel="noopener noreferrer"
							:disabled="savingJudgment"
						>
							Preview
						</BaseButton>

						<BaseButton
							:disabled="savingJudgment"
							color="primary"
							@click="handleSubmit(item_id)"
						>
							Approve
						</BaseButton>

						<BaseButton
							:disabled="savingJudgment"
							color="red"
							@click="() => (judgement = 'deny')"
						>
							Deny
						</BaseButton>

						<BaseButton
							:disabled="savingJudgment"
							text
							class="pr-2 pt-2"
							@click="judgement = 'accept'"
						>
							Approve with Message
						</BaseButton>
					</div>
					<ValidationObserver v-else slim>
						<form
							class="flex flex-row items-end justify-end space-x-2"
							@submit.prevent="handleSubmit(item_id)"
						>
							<ValidationProvider
								v-slot="{ errors }"
								ref="provider"
								:name="`${judgement === 'deny' ? 'Denial' : 'Approval'} Message`"
								slim
								rules="min:4"
							>
								<BaseTextArea
									:value.sync="message"
									:autofocus="true"
									:label="`Enter ${
										judgement === 'deny'
											? 'Reason for Denial'
											: 'Approval Message'
									}`"
									dense
									:message="errors[0]"
									class="grow"
									@update:value="handleChange"
									@keyup.escape="hideMessage"
								/>
							</ValidationProvider>

							<BaseButton color="red" @click="hideMessage">Cancel</BaseButton>

							<BaseButton type="submit" :disabled="!message" color="primary"
								>Confirm</BaseButton
							>
						</form>
					</ValidationObserver>
				</footer>
			</div>
		</template>
	</BaseExpansionList>
</template>

<script setup>
import { ref } from 'vue';

import { format, formatDistanceToNow } from 'date-fns';
import useAlertStore from '@/stores/alerts';
import ProgressBar from '@/components/ui/ProgressBar';
import { useApi } from '@/composables/useApi';
import useOverviewStore from '@/stores/overview';
import BannerReview from '@/components/overview/BannerReview';
import AvatarReview from '@/components/overview/AvatarReview';
import SquareReview from '@/components/overview/SquareReview';
import BaseTextArea from '@/components/ui/BaseTextArea';
import { debounce } from 'lodash';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';

defineProps({
	items: { type: Array, required: true },
});

const overviewStore = useOverviewStore();

const judgement = ref(null);
const savingJudgment = ref(false);
const provider = ref(null);
const message = ref(null);
const saveProgress = ref(0);

async function handleSubmit(item_id) {
	savingJudgment.value = true;
	try {
		await useApi('overview/api/queue/judgment/', {
			message: 'An error occurred while recording decision, please try again later.',
		}).post({
			item_id: item_id,
			decision: judgement.value || 'accept',
			reason: message.value,
		});
	} catch (error) {
		const alertStore = useAlertStore();

		alertStore.addAlert({
			message: 'An error occurred while recording decision, please try again later',
		});
	} finally {
		await overviewStore.getApprovalQueue();
		message.value = null;
		judgement.value = null;
		savingJudgment.value = false;
	}
}
const handleChange = debounce(async value => {
	message.value = value;
	await provider.value.validate();
}, 1000);
function hideMessage() {
	message.value = null;
	judgement.value = null;
}
</script>

<style scoped module lang="scss"></style>
