<template>
	<div>
		<DomainValidator :searching="searching" @search="searchDomains" />
		<div v-if="searching" class="my-10 flex items-center gap-4">
			<div class="whitespace-nowrap text-2xl">
				Checking availability for
				<span class="text-blue-400">
					<span class="text-gray-400">www.</span>{{ domainName }}
				</span>
			</div>
			<ProgressBar class="!w-auto grow !items-start" />
		</div>

		<div v-if="searchResults" class="mb-12 mt-4 gap-4">
			<h3 class="text-xl">Select one of the available domains:</h3>

			<div
				v-for="domain in searchResults"
				:key="domain.name"
				class="mt-3 flex items-center justify-between"
			>
				<div class="flex items-center gap-5">
					<FAIcon class="text-4xl text-green-600" icon="fa-thin fa-earth-americas" />
					<div class="flex items-center gap-6">
						<div class="text-xl text-gray-400">
							www.<span
								:class="[domain.available ? 'font-medium text-green-700' : '']"
								>{{ domain.name }}</span
							>
						</div>

						<div
							v-if="domain.exact && domain.available"
							class="inline-flex items-center rounded-full border border-green-700 bg-green-100 px-2 py-0.5 text-xs font-medium text-green-700"
						>
							EXACT MATCH
						</div>
						<div
							v-else-if="domain.available"
							class="inline-flex items-center rounded-full border border-gray-600 bg-gray-50 px-2 py-0.5 text-xs font-medium text-gray-600"
						>
							Alternative Name
						</div>
					</div>
				</div>
				<BaseButton
					v-if="domain.owned"
					:to="{
						// todo: re-enable this once all domain types can use the editor for settings
						// name: 'editorIndex',
						// params: { domainName: domain.name },
						name: 'domains',
					}"
					color="primary"
					outline
					rounded="md"
				>
					Already Owned
				</BaseButton>

				<BaseButton
					v-else
					:color="domain.available ? 'success' : 'gray'"
					:dense="!domain.available"
					:disabled="!domain.available"
					outline
					rounded="md"
					@click="selectName(domain.name)"
				>
					{{ domain.available ? 'Available' : 'Unavailable' }}
				</BaseButton>
			</div>
		</div>

		<div class="mt-10 rounded-md bg-white p-4">
			<h3 class="text-xl">Pick a good Domain Name:</h3>
			<ul class="mt-5 grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-4">
				<li class="suggestion">
					<p class="tldr">Keep it short and simple</p>
					You're going to put it on business cards, direct mail, novelty items,
					billboards, and more. Your primary domain name should be easily memorized, typed
					or pronounced.
				</li>
				<li class="suggestion">
					<p class="tldr">Avoid hyphens or dashes</p>
					People forget to type the hyphens, and it's harder to explain over the phone.
				</li>
				<li class="suggestion">
					<p class="tldr">Be memorable & meaningful</p>
					It's helpful if the domain name relates to you or your business name. If you're
					incorporated, you can use the word "agency" at the end of the domain, such as
					"www.JaneDoeAgency.com".
				</li>
				<li class="suggestion">
					<p class="tldr">Check the guidelines</p>
					Refer to the the Overview page in the Agent Domain Compliance section:
					<a
						href="http://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/agent_domain/agent_domain_mirus.shtml"
						target="_blank"
						rel="noopener noreferrer"
					>
						Agent Domain/Internet Redirect Naming Rules & Guidelines
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>
<script setup>
import { computed, ref, onMounted, watchEffect, inject } from 'vue';
import { storeToRefs } from 'pinia';

import useAgentStore from '@/stores/agent/agent';
import useDomainsStore from '@/stores/agent/agent-domains';

import { useApi } from '@/composables/useApi';

import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

import DomainValidator from '@/components/domains/DomainValidator';

const emit = defineEmits('update:domainName');

const agentStore = useAgentStore();
const domainsStore = useDomainsStore();

const { agent } = storeToRefs(agentStore);
const { domains } = storeToRefs(domainsStore);

const { domainName, updateDomainName } = inject('domainName');

const searching = ref(false);
const searchResults = ref(null);

const ownedDomains = computed(() => {
	return (
		domains.value?.filter(domain => !domain.is_cancelled).map(domain => domain.domain_name) ??
		[]
	);
});

async function searchDomains() {
	if (!domainName.value) {
		console.error('domain name was not supplied when searchDomains was called');
		return;
	}
	searchResults.value = null;
	searching.value = true;

	const { data } = await useApi(
		`api/v3/agents/${agent.value.associate_id}/domains/check/?${new URLSearchParams({
			domain_name: domainName.value,
		})}`,
		{
			agent: false,
			message: 'There was an issue getting the search results! Please try again later.',
		}
	).json();

	const exactOwned = ownedDomains.value.includes(domainName.value);
	const exact = {
		exact: true,
		name: domainName.value,
		owned: exactOwned,
		available: !exactOwned && data.value?.exact_match.available,
	};

	const similar = data.value?.sld_matches.map(({ domain_name, available, premium }) => {
		const owned = ownedDomains.value.includes(domain_name);
		return {
			exact: false,
			name: domain_name,
			available: available && !owned,
			owned,
			premium,
		};
	});

	searchResults.value = [exact, ...similar];
	searching.value = false;
}

function selectName(name) {
	updateDomainName(name);
	emit('continue');
}

onMounted(async () => {
	await domainsStore.ensureDomains();
});
watchEffect(() => {
	if (domainName.value !== searchResults.value?.[0]?.name) {
		searchResults.value = null;
	}
});
</script>

<style scoped>
.suggestion {
	@apply relative rounded-md border border-slate-300 p-3 pt-5 text-sm;
}
.tldr {
	@apply absolute top-0 -translate-y-1/2 transform rounded-full border bg-indigo-50 px-2 py-0.5 text-sm font-medium;
}
</style>
