<template>
	<div
		class="grid gap-2"
		:class="override_type === 'staff_avatar' ? 'grid-cols-8' : 'grid-cols-12'"
	>
		<figure v-if="override_type === 'agent_avatar'" class="figure">
			<img width="100%" :src="sfLink" alt="EL Photo" class="image bg-checkerboard" />
			<figcaption class="figcaption bg-blue-700">EL Photo</figcaption>
		</figure>

		<figure class="figure">
			<img
				v-if="currentLink"
				:src="currentLink"
				alt="Current Choice"
				class="img bg-checkerboard grow"
			/>
			<figcaption
				class="figcaption"
				:class="[previous_value ? 'bg-blue-700' : 'mt-auto bg-red-700']"
			>
				{{ previous_value ? '' : 'No ' }} Current {{ label }} Photo
			</figcaption>
		</figure>

		<figure class="figure">
			<img :src="value" alt="New Choice" class="img bg-checkerboard" />
			<figcaption class="figcaption bg-blue-700">New Photo</figcaption>
		</figure>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	value: { type: String, required: true },
	previous_value: { type: String },
	agent: { type: Object, required: true },
	override_type: { type: String, required: true },
});

const sfLink = computed(
	() =>
		`https://ac2.st8fm.com/associate-photos/${props.agent.associate_id[0]}/${props.agent.associate_id}/formalColorFull.jpg`
);
const currentLink = computed(() => {
	if (props.previous_value) {
		return props.previous_value;
	} else if (props.override_type === 'agent_avatar') {
		return sfLink.value;
	} else {
		return undefined;
	}
});
const label = computed(() => {
	switch (props.override_type) {
		case 'staff_avatar':
			return 'Staff';
		case 'agent_avatar':
			return 'Agent Profile';
		default:
			return 'Image';
	}
});
</script>

<style>
.figure {
	@apply col-span-12 flex flex-col justify-end overflow-hidden rounded drop-shadow-lg sm:col-span-4;
}
.img {
	@apply w-full rounded-t object-contain object-bottom;
}
.figcaption {
	@apply mt-0 p-4 text-center font-bold tracking-wide text-white;
}
</style>
