<template>
	<div class="grid grid-cols-8 gap-2">
		<figure class="figure">
			<img
				v-if="previous_value"
				:src="previous_value"
				alt="Current Choice"
				class="img bg-checkerboard"
			/>
			<figcaption
				class="figcaption"
				:class="[previous_value ? 'bg-blue-700' : 'mt-auto bg-red-700']"
			>
				{{ previous_value ? '' : 'No ' }} Current {{ label }}
			</figcaption>
		</figure>

		<figure class="figure">
			<img :src="value" alt="New Choice" class="img bg-checkerboard" />
			<figcaption class="figcaption bg-blue-700">New {{ label }}</figcaption>
		</figure>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	value: { type: String, required: true },
	previous_value: { type: String },
	override_type: { type: String, required: true },
});

const label = computed(() => {
	switch (props.override_type) {
		case 'jobs_team_images':
			return 'Jobs Image';
		case 'mission_tab_images':
			return 'Mission Tab Image';
		case 'team_tab_images':
			return 'Team Tab Image';
		default:
			return 'Image';
	}
});
</script>

<style>
.figure {
	@apply col-span-12 flex flex-col justify-end overflow-hidden rounded drop-shadow-lg sm:col-span-4;
}
.img {
	@apply w-full rounded-t object-contain object-bottom;
}
.figcaption {
	@apply mt-0 p-4 text-center font-bold tracking-wide text-white;
}
</style>
