<template>
	<div>
		<ValidationObserver
			v-slot="{ dirty, valid, pristine, handleSubmit }"
			class="rounded-md bg-white p-2 text-gray-600 dark:bg-gray-800 dark:text-gray-300"
			tag="div"
		>
			<h3 class="pb-4 text-2xl">Search Email Logs (last 7 days)</h3>
			<form
				class="grid grid-cols-2 gap-4"
				@submit.prevent="handleSubmit(getEmailSearchResults)"
			>
				<ValidationProvider v-slot="{ errors }" :rules="{ required: !toEmail }" name="from">
					<TextFieldInput
						:value.sync="fromEmail"
						label="From Email"
						placeholder="from email"
					>
						<template v-if="errors.length > 0" #message>
							You must fill in at least one field
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider v-slot="{ errors }" :rules="{ required: !fromEmail }" name="to">
					<TextFieldInput :value.sync="toEmail" label="To Email" placeholder="to email">
						<template v-if="errors.length > 0" #message>
							You must fill in at least one field
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<BaseButton
					:disabled="pristine || (dirty && !valid)"
					class="col-span-2 w-1/4 justify-self-end"
					type="submit"
				>
					Search
				</BaseButton>
				<BaseButton
					:disabled="!showDownload"
					:href="showDownload ? downloadApiUrl : null"
					class="col-span-2 w-1/4 justify-self-end"
				>
					Download CSV
				</BaseButton>
			</form>
		</ValidationObserver>

		<ProgressBar
			v-if="loading"
			class="mt-5 !h-[30vh] w-full rounded-md bg-white p-5 dark:bg-gray-900"
			text="Loading search results..."
		/>
		<SortableTable
			v-else
			:headers="headers"
			:items="parsedEmails"
			title="Search Results"
			item-unique-key="arrival"
			show-pagination
			show-search
			dense
			show-title
			:inset="false"
			class="mt-5 shrink grow-0"
			:expand-all="false"
		>
			<template #item.arrival="{ arrival }">
				{{ format(new Date(arrival), 'MMM dd, yyy h:mm:ss a') }}
			</template>
			<template #item.from="{ from }">
				{{ from }}
			</template>
			<template #item.to="{ to }">
				{{ to }}
			</template>
			<template #item.delivery_response="{ delivery_response }">
				{{ delivery_response }}
			</template>
			<template #item.denial_msg="{ denial_msg }">
				{{ shorthandDenailMessage(denial_msg) }}
			</template>

			<template #item_expanded="{ denial_msg }">
				<div class="p-4">
					<span v-if="!denial_msg"> null </span>
					<pre
						v-else-if="denial_msg.match(/^\{[\d\D]+\}$/)"
						lang="json"
						v-html="formatDenialMessage(denial_msg)"
					/>
					<span v-else>{{ denial_msg }}</span>
				</div>
			</template>
		</SortableTable>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { extend } from 'vee-validate';
import { format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import SortableTable from '@/components/ui/SortableTable.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

extend('atLeastOne', {
	params: ['comparison'],
	validate(value, { comparison }) {
		return Boolean(value || comparison);
	},
	message: 'This field value must be between {comparison}',
});

const headers = [
	{ text: 'Arrival', value: 'arrival', sortable: true, wrap: false },
	{ text: 'From', value: 'from', filterable: true, sortable: true, wrap: false },
	{ text: 'To', value: 'to', filterable: true, sortable: true, wrap: false },
	{ text: 'Delivery Response', value: 'delivery_response', truncate: true },
	{ text: 'Denial Message', value: 'denial_msg', truncate: true },
];

const loading = ref(false);
const fromEmail = ref(null);
const toEmail = ref(null);
const emails = ref([]);

const parsedEmails = computed(() =>
	emails.value.map(email => ({ ...email, expandable: Boolean(email.denial_msg) }))
);

const showDownload = computed(() => {
	return !loading.value && emails.value?.length;
});

const searchApiUrl = computed(() => {
	const params = {};

	if (toEmail.value !== null) {
		params.to = toEmail.value;
	}

	if (fromEmail.value !== null) {
		params.from = fromEmail.value;
	}
	return `api/v3/agents/search_emails/?${new URLSearchParams(params)}`;
});

const downloadApiUrl = computed(() => {
	return `/${searchApiUrl.value}&format=csv`;
});

async function getEmailSearchResults() {
	emails.value = [];
	loading.value = true;

	try {
		const result = await useApi(searchApiUrl.value, {
			agent: false,
			message: 'There was a issue with pulling your email search. Please try again later!',
		}).json();

		emails.value = result.data.value?.emails;
	} catch (error) {
		console.error(error);
	} finally {
		loading.value = false;
	}
}

function shorthandDenailMessage(message) {
	if (!message) {
		return null;
	}

	try {
		const jsonData = JSON.parse(message);
		return jsonData.msg;
	} catch (error) {
		// if parse fails, fall back to the original string
		return message;
	}
}

function formatDenialMessage(message) {
	// message can be a stringified JSON object or a plain string
	try {
		return JSON.parse(message);
	} catch (error) {
		// if parse fails, fall back to the original string
		return message;
	}
}
</script>

<style module lang="scss">
.table {
}
.body {
	overflow: auto;
}
</style>
