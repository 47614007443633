<template>
	<div class="space-y-10">
		<div class="text-3xl">Choose your Quote Landing Page appearance</div>

		<div class="flex items-center justify-center space-x-4">
			<div class="mx-2 text-2xl">View page on different devices:</div>
			<div class="flex grow-0 flex-wrap">
				<BaseButton
					v-for="devOption in ['desktop', 'tablet', 'phone']"
					:key="devOption"
					class="m-1"
					dense
					rounded="md"
					:color="device === devOption ? 'primary' : 'secondary'"
					@click="device = devOption"
				>
					{{ devOption }}
				</BaseButton>
			</div>
		</div>

		<div class="mx-10 flex items-start justify-between space-x-10">
			<div v-for="(v, key) in qlpOptions" :key="key" class="flex flex-col items-center">
				<div class="text-xl">
					Preview of <b>{{ v.name }} QLP</b> on a {{ device }}
				</div>
				<img
					class="w-full rounded-md border-2 border-solid border-gray-400 bg-slate-200 p-2 shadow-md"
					:src="v.img[device]"
					:alt="`Preview of ${v.name} QLP on a ${device}`"
				/>
			</div>
		</div>

		<div v-if="choices" class="mx-10 space-y-8">
			<div
				v-for="(selection, domain) in activeDomains"
				:key="domain"
				class="flex space-x-4 rounded-md border-2 border-gray-200 p-4"
			>
				<img
					class="w-full rounded-md border-2 border-solid border-gray-400 bg-slate-200 p-2 shadow-md md:w-1/2"
					:src="qlpOptions[choices[domain]].img[device]"
					:alt="`Preview of QLP on a `"
				/>
				<div class="w-full space-y-4">
					<div
						class="border-b border-b-gray-400 text-sm font-medium sm:text-xl md:text-2xl"
					>
						<span class="hidden text-gray-400 sm:inline-block">www.</span>{{ domain }}
					</div>
					<div class="flex flex-wrap items-center justify-between">
						<div class="space-y-4">
							<div
								v-for="(qlp, org) in qlpOptions"
								:key="org"
								class="relative flex items-start"
							>
								<div class="flex h-5 flex-wrap items-center space-x-1 sm:space-x-4">
									<input
										:id="`${domain}-${org}`"
										v-model="choices[domain]"
										type="radio"
										:value="org"
										:name="`${domain}-qlp-selection`"
										:aria-labelledby="`#${domain}-${org}`"
										:checked="org === choices[domain]"
										class="h-6 w-6 border border-solid border-gray-400 text-green-600 focus:ring-green-500"
									/>
									<label
										:for="`${domain}-${org}`"
										class="text-sm font-medium text-gray-700 sm:text-lg"
									>
										{{ qlp.name }}
									</label>
									<span
										v-if="activeDomains[domain] === org"
										class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
									>
										Active
									</span>
								</div>
							</div>
						</div>
						<BaseButton
							v-if="!saving && activeDomains[domain] !== choices[domain]"
							class="m-2"
							@click="setPreference(domain)"
						>
							Save Changes
						</BaseButton>
						<div
							v-if="saving && savingDomain === domain"
							class="mr-8 flex grow-0 items-center space-x-2 text-2xl text-gray-400"
						>
							<FAIcon spin icon="circle-notch" />
							<span>Saving &hellip;</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ProgressBar v-else class="w-full" />
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { storeToRefs } from 'pinia';

import qlp_mirus_desktop from '@/assets/images/editor/qlp/qlp_mirus_desktop.jpg';
import qlp_mirus_phone from '@/assets/images/editor/qlp/qlp_mirus_phone.jpg';
import qlp_mirus_tablet from '@/assets/images/editor/qlp/qlp_mirus_tablet.jpg';
import qlp_sf_desktop from '@/assets/images/editor/qlp/qlp_sf_desktop.jpg';
import qlp_sf_phone from '@/assets/images/editor/qlp/qlp_sf_phone.jpg';
import qlp_sf_tablet from '@/assets/images/editor/qlp/qlp_sf_tablet.jpg';
import BaseButton from '@/components/ui/BaseButton';
import ProgressBar from '@/components/ui/ProgressBar.vue';

import useEditorChangesStore from '@/stores/editorChanges';
import useQlpStore from '@/stores/agent/agent-qlp';

const editorChangesStore = useEditorChangesStore();
const qlpStore = useQlpStore();

const { activeDomains } = storeToRefs(qlpStore);

const qlpOptions = {
	sf: {
		name: 'SF',
		img: {
			desktop: qlp_sf_desktop,
			tablet: qlp_sf_tablet,
			phone: qlp_sf_phone,
		},
	},
	mirus: {
		name: 'Mirus',
		img: {
			desktop: qlp_mirus_desktop,
			tablet: qlp_mirus_tablet,
			phone: qlp_mirus_phone,
		},
	},
};

const device = ref('desktop');
const choices = ref(null);
const saving = ref(false);
const savingDomain = ref(null);

async function setPreference(domain) {
	savingDomain.value = domain;
	saving.value = true;
	await editorChangesStore.controlSaveDialog({ status: 'saving' });
	await qlpStore.changePreference(domain, choices.value[domain]);
	await editorChangesStore.controlSaveDialog({ status: 'saved', domain, route: '/quote' });
	saving.value = false;
}

onMounted(async () => {
	await qlpStore.getPreferences();
	if (activeDomains.value) {
		choices.value = cloneDeep(activeDomains.value);
	}
	saving.value = false;
});
</script>

<style></style>
