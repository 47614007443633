<template>
	<header
		class="sticky top-20 z-30 row-start-3 flex max-h-[60vh] w-full flex-wrap items-center justify-between gap-4 overflow-auto bg-gray-50 p-4 shadow-md shadow-gray-900/20 print:hidden"
	>
		<h2 class="text-4xl font-semibold uppercase text-gray-800">{{ title }}</h2>
		<slot />
	</header>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
const route = useRoute();

const title = computed(() => route?.meta?.title || route?.name);
</script>
