<template>
	<div v-if="account.setup" class="m-4">
		<h3 class="mb-4 text-xl">Additional Calltracking Setup Required</h3>

		<div class="grid grid-cols-2">
			<div>
				<p>
					Our default pick for your local area code,
					{{ account.setup.default_area_code }}, was not available!
				</p>
				<p>To continue setting up call tracking, enter a different area code.</p>

				<p class="mb-1">Here are some area codes from your state that you can try:</p>
				<ul class="flex list-none flex-wrap gap-2 pl-0">
					<li v-for="item in available" :key="item">
						<BaseButton color="gray" @click="onCodeChange(item)">
							{{ item }}
						</BaseButton>
					</li>
				</ul>

				<p class="mb-1 mt-4">Previously attempted area codes that were not available:</p>
				<ul class="flex list-none flex-wrap gap-2 pl-0">
					<li v-for="attempt in previousAttempts" :key="attempt">
						<BaseButton color="gray" @click="onCodeChange(attempt)">
							{{ attempt }}
						</BaseButton>
					</li>
				</ul>
			</div>

			<div class="flex flex-col gap-2">
				<TextFieldInput
					ref="textField"
					:value.sync="code"
					type="number"
					:disabled="loading"
					label="Enter a different area code"
					placeholder="area code"
				/>

				<BaseButton color="primary" :disabled="loading || !code" @click="onTryNew">
					Try This Area Code
				</BaseButton>
				<ProgressBar v-if="loading" text="Loading..." />
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import store from 'store2';

import TextFieldInput from '@/components/ui/TextFieldInput';
import ProgressBar from '@/components/ui/ProgressBar';
import useCalltrackingStore from '@/stores/agent/agent-calltracking';
import BaseButton from '@/components/ui/BaseButton.vue';

const props = defineProps({
	account: {
		type: Object,
		required: true,
	},
});

const calltrackingStore = useCalltrackingStore();

const code = ref('');
const previousAttempts = ref([]);
const loading = ref(false);

const available = computed(
	() =>
		[props.account?.setup?.area_codes, previousAttempts.value].reduce((a, b) => {
			return a?.filter(c => !b?.includes(c));
		}) ?? []
);

function onCodeChange(value) {
	code.value = value.replace(/\D/g, '').substring(0, 3);
}

async function onTryNew() {
	loading.value = true;
	await calltrackingStore.setAreaCode(code.value);
	loading.value = false;
}

onMounted(() => {
	const attemptsFromStorage = store.get('previousAttempts', []);
	const newPreviousAttempts = [props.account?.setup?.default_area_code, ...attemptsFromStorage];
	store.set(
		'previousAttempts',
		[...new Set(newPreviousAttempts)].filter(attempt => attempt)
	);
	previousAttempts.value = [...new Set(newPreviousAttempts)];
});
</script>

<style scoped></style>
