<template>
	<div class="">
		<ComplianceChecker
			key="compliance-checker-checker"
			class="mb-4"
			:value="text"
			hide-cancel
			height="200px"
			:show-disclaimer="false"
			:required="false"
			:name="'Compliance Checker Checker'"
			:confirm-changes="false"
			:domain="DOMAIN"
			:product-type="PRODUCT_TYPE"
			@update:compliance-response="complianceResult = $event"
			@update:compliance-text="text = $event"
		/>
		<div class="flex gap-2">
			<div
				class="flex w-1/2 flex-col overflow-y-auto rounded-md bg-white p-3 text-gray-800 dark:bg-gray-800"
			>
				<header class="mb-4 flex h-fit w-full shrink justify-between">
					<span class="text-xl font-bold text-sf-charcoal dark:text-gray-100">
						Raw Text
					</span>
					<BaseButton class="w-fit" color="primary" @click="copyText(text)">
						Copy
					</BaseButton>
				</header>
				<blockquote
					class="w-full grow whitespace-pre-wrap rounded-md bg-gray-100 p-4 dark:bg-gray-900 dark:text-gray-100"
				>
					{{ text }}
				</blockquote>
			</div>
			<div
				class="flex max-h-[34rem] w-1/2 flex-col overflow-y-auto overflow-y-auto rounded-md bg-white p-3 text-gray-800 dark:bg-gray-800 dark:text-gray-100"
			>
				<header class="mb-4 flex h-fit w-full justify-between">
					<span class="text-xl font-bold text-sf-charcoal dark:text-gray-100">
						Raw Mysfdomain API Response
					</span>
					<BaseButton
						class="w-fit"
						color="primary"
						@click="copyText(formattedComplianceResult)"
					>
						Copy
					</BaseButton>
				</header>

				<blockquote
					class="w-full whitespace-pre-wrap rounded-md bg-gray-100 p-4 dark:bg-gray-900"
				>
					{{ formattedComplianceResult }}
				</blockquote>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';

import BaseButton from '@/components/ui/BaseButton.vue';

import ComplianceChecker from '@/components/MXEditor/ComplianceChecker';

const DOMAIN = 'm2foryou.com';
const PRODUCT_TYPE = 'm2';

const text = ref('');
const complianceResult = ref('');
const formattedComplianceResult = computed(() => JSON.stringify(complianceResult.value, null, 2));

async function copyText(text) {
	navigator.clipboard.writeText(text);
}
</script>
