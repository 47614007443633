<template>
	<fieldset class="gap flex flex-wrap justify-start gap-x-4 gap-y-2">
		<legend class="mb-1 font-medium text-black">{{ legend }}</legend>
		<CheckboxInput
			v-for="option in options"
			:key="option.value"
			:name="`name-${option.value}`"
			:checked="innerValues[option.value]?.value ?? false"
			:value="option.value"
			:disabled="option.disabled || false"
			class="my-0 px-3 py-3"
			@update:checked="handleChange"
		>
			{{ option.label || option.value }}
		</CheckboxInput>
		<slot />
	</fieldset>
</template>

<script setup>
import { computed, ref, reactive, watchEffect } from 'vue';
import CheckboxInput from '@/components/ui/CheckboxInput';

const emit = defineEmits('update:values');
const props = defineProps({
	name: { type: String, required: true },
	options: { type: Array, required: true },
	values: { type: Array, required: true },
	legend: { type: String, required: true },
});

const innerValues = reactive({});

const selected = computed(() => {
	return props.options
		.filter(option => innerValues[option.value]?.value ?? false)
		.map(option => option.value);
});

function updateInnerValue(key, checked) {
	innerValues[key].value = checked;
	emit('update:values', selected.value);
}

watchEffect(() => {
	// set all available options to false initially
	for (let v of props.options) {
		innerValues[v.value] = ref(false);
	}

	// update any currently checked options
	for (let v of props.values) {
		innerValues[v].value = true;
	}
});

defineExpose({
	innerValues,
	updateInnerValue,
});
</script>
