<template>
	<div
		class="flex flex-col justify-between rounded bg-white p-4 text-gray-700 dark:bg-gray-600 dark:text-gray-100"
	>
		<span v-if="title" class="mb-4 text-4xl">{{ title }}</span>
		<div class="flex flex-col items-start justify-stretch gap-4 2xl:flex-row">
			<div class="flex grow basis-1/2 flex-col justify-end">
				<span class="mb-2 text-sm font-medium uppercase leading-tight tracking-wider"
					>Displaying stats for:</span
				>
				<span
					class="inline-flex w-full items-center justify-center whitespace-nowrap rounded bg-orange-100 px-3 py-2 font-bold text-orange-800 dark:bg-orange-700 dark:text-orange-100"
				>
					{{ rangeLabel }}
				</span>
			</div>
			<div class="flex grow basis-1/4 flex-col justify-end">
				<span class="mb-2 text-sm font-medium uppercase leading-tight tracking-wider">
					Select a date range:
				</span>
				<div class="inline-flex items-center gap-2">
					<BaseSelect
						id="date-range-selector"
						:items="choices"
						:value="{ text: label }"
						value-type="object"
						item-value-key="text"
						label="Select a date range"
						pill
						dark
						@update:value="onSelect"
					/>
					<BaseDialog dense max-width="320">
						<template #activator="{ openDialog }">
							<BaseButton class="!h-full" @click="openDialog"
								>Custom Range</BaseButton
							>
						</template>
						<BaseDatePicker
							:value.sync="customDates"
							range
							:min="datePickerMin"
							:max="datePickerMax"
						/>
						<template #actions="{ closeDialog }">
							<BaseButton value="cancel" text color="red" @click="closeDialog()">
								Cancel
							</BaseButton>
							<BaseButton
								:disabled="!customDatesReady"
								@click="
									onCustomConfirm();
									closeDialog();
								"
							>
								Apply Range
							</BaseButton>
						</template>
					</BaseDialog>
				</div>
			</div>
		</div>
		<p class="mb-0 ml-2 mt-2 text-xs">(Longer ranges may take a few moments to populate.)</p>
	</div>
</template>

<script setup>
import { endOfDay, startOfDay, isValid } from 'date-fns';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

import useTimeStore from '@/stores/time';

import BaseSelect from '@/components/ui/BaseSelect';
import BaseButton from '@/components/ui/BaseButton';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseDialog from '@/components/ui/BaseDialog';

const props = defineProps({
	max: { type: [String, Date], required: false },
	min: { type: [String, Date], required: false },
	title: { type: [String], required: false },
});

const timeStore = useTimeStore();
const { label, choices, range: rangeLabel } = storeToRefs(timeStore);

const customDates = ref([]);
const customDatesReady = computed(() => customDates.value.length === 2);

const datePickerMin = computed(() => {
	if (isValid(props.min)) {
		return props.min;
	}
	return new Date(2000, 0, 1);
});

const datePickerMax = computed(() => {
	if (isValid(props.max)) {
		return props.max;
	}
	return new Date();
});

function onSelect(value) {
	timeStore.setChoice(value);
}

function onCustomConfirm() {
	timeStore.setCustomRange(startOfDay(customDates.value[0]), endOfDay(customDates.value[1]));
}

onMounted(() => {
	if (!label.value) {
		timeStore.setChoice(choices.value[0]);
	}
});
</script>

<style scoped module lang="scss"></style>
