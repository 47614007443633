<template>
	<div class="inline-flex items-center" aria-live="polite">
		<span
			v-if="switching"
			class="inline-flex h-7 w-7 items-center justify-center rounded-full border-2 border-gray-600 bg-gray-100 drop-shadow-md"
		>
			<FAIcon spin size="lg" icon="arrows-spin" class="text-orange-400" />
			<span class="sr-only">{{ disabled ? 'Enabling' : 'Disabling' }} route</span>
		</span>

		<BaseToggle
			v-else
			:value="!disabled"
			:a11y-label="a11yLabel"
			dark
			@update:value="updateRoute"
		/>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import BaseToggle from '@/components/ui/BaseToggle';
import useEditorChangesStore from '@/stores/editorChanges';

const props = defineProps({
	unique_key: { type: String, required: true },
	disabled: { type: Boolean, required: true },
	subRouteKeys: { type: Array, default: () => [] },
	a11yLabel: { type: String, required: true },
});

const editorChangesStore = useEditorChangesStore();
const switching = ref(false);

function updateRoute() {
	switching.value = true;
	const routeChanges = [
		{
			unique_key: props.unique_key,
			disabled: !props.disabled,
		},
		...props.subRouteKeys.map(key => {
			return { unique_key: key, disabled: !props.disabled };
		}),
	];
	editorChangesStore.putRouteChanges(routeChanges).then(() => {
		switching.value = false;
	});
}
</script>
