<template>
	<ValidationObserver v-slot="{ handleSubmit }" tag="section" class="h-full overflow-auto">
		<form
			class="flex h-full gap-4 rounded-md bg-white p-4 text-gray-600"
			@submit.prevent="handleSubmit(onMove)"
		>
			<ValidationProvider
				v-if="movableDomains"
				v-slot="{ validate }"
				:rules="{ required: true }"
				class="h-full basis-1 sm:basis-7/12"
			>
				<RadioGroup
					id="selectedDomain"
					:value.sync="selectedDomain"
					:options="movableDomains"
					option-key="name"
					class="h-full overflow-auto"
					legend="Select an active domain to move"
					@update:value="validate"
				>
					<template #label="{ name, can_move }">
						<span> {{ name }} </span>
						<span v-if="can_move"> (movable)</span>
						<span v-else> (not movable, owned by someone outside mirus) </span>
					</template>
				</RadioGroup>
			</ValidationProvider>

			<ProgressBar v-else />

			<div class="flex flex-col gap-1 pt-4">
				<ValidationProvider v-slot="{ errors, validate }" mode="eager" rules="aliasLength">
					<TextFieldInput
						:value.sync="selectedAlias"
						light
						:disabled="status === 'moving'"
						:error-messages="errors"
						label="Enter a valid Agent Alias"
						placeholder="alias"
						@update:value="validate"
					/>
				</ValidationProvider>

				<ValidationProvider v-slot="{ errors }" name="Domain type" rules="required">
					<BaseSelect
						id="domain_type_for_moved_domain"
						outlined
						show-label
						:value.sync="selectedProductType"
						:disabled="status === 'moving'"
						:error-messages="errors"
						label="Select Domain Type for Target Agent"
						class="mt-4"
						padding="8"
						:items="domainTypeOptions"
					/>
				</ValidationProvider>

				<BaseToggle
					:value.sync="shouldRemoveRedirects"
					class="mt-4"
					visible-label="Remove all existing email redirects?"
				/>
				<BaseButton :disabled="Boolean(status)" color="primary" type="submit">
					{{ status === 'moving' ? '...Moving' : 'Move' }}
				</BaseButton>
			</div>
		</form>

		<BaseAlert v-if="responseErrors" class="mt-4" type="error">
			<ul>
				<li v-for="(errorValue, errorKey) in responseErrors" :key="errorKey">
					<strong>{{ errorKey }}: </strong>
					<span>{{ errorValue }}</span>
				</li>
			</ul>
		</BaseAlert>
		<BaseAlert v-if="status === 'done'" type="success" class="mt-4">
			Domain was successfully moved!
		</BaseAlert>
	</ValidationObserver>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { extend } from 'vee-validate';
import { storeToRefs } from 'pinia';

import useDomainsStore from '@/stores/agent/agent-domains';

import ProgressBar from '@/components/ui/ProgressBar.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseToggle from '@/components/ui/BaseToggle.vue';
import RadioGroup from '@/components/ui/RadioGroup.vue';

const domainStore = useDomainsStore();

const domainTypeOptions = [
	{ value: 'sf_microsite', text: 'Secure Microsite Proxy' },
	{ value: 'm1', text: 'M1' },
	{ value: 'm2', text: 'M2' },
	{ value: 'parked', text: 'Secure Parked' },
];

extend('aliasLength', {
	validate: value => [4, 6].includes(value.length) || 'Alias should be 4 or 6 characters',
});

const status = ref('loading');

const responseErrors = ref(null);

const shouldRemoveRedirects = ref(true);
const selectedAlias = ref('');
const selectedDomain = ref(null);
const selectedProductType = ref(null);

const { movableDomains } = storeToRefs(domainStore);

async function onMove() {
	status.value = 'moving';
	try {
		const errors = await domainStore.moveDomain({
			target_alias: selectedAlias.value,
			target_product_type: selectedProductType.value,
			remove_redirects: shouldRemoveRedirects.value,
			domain_name: selectedDomain.value,
		});
		status.value = 'done';

		if (errors) {
			responseErrors.value = errors;
		}
	} catch (error) {
		status.value = null;
	}
}
onMounted(async () => {
	await domainStore.getMovableDomains();
	status.value = null;
	responseErrors.value = null;
});
</script>

<style scoped></style>
