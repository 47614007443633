<template>
	<fieldset :disabled="disabled">
		<div
			class="relative mt-1 flex enabled:ring-1 enabled:ring-gray-400 enabled:focus:ring-orange"
		>
			<textarea
				v-bind="{ ...$attrs, cols, rows, value, id, placeholder }"
				:id="id"
				ref="input"
				class="peer w-full pr-10 focus:outline-none enabled:ring-1 enabled:ring-gray-400 enabled:focus:ring-orange disabled:cursor-not-allowed sm:text-sm"
				:class="{ 'resize-none': !resizable, 'placeholder-transparent': !showPlaceholder }"
				:placeholder="placeholder"
				@input="handleChange"
			/>
			<label
				:for="id"
				:class="[
					disabled ? 'text-gray-500' : 'text-gray-900',
					showPlaceholder
						? 'top-0 -mt-4'
						: 'mb-5 peer-placeholder-shown:top-1.5 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-5 peer-focus:left-0 peer-focus:text-sm dark:peer-placeholder-shown:text-gray-300',
				]"
				class="absolute left-1 text-sm text-gray-600 transition-all peer-focus:text-orange-600 dark:text-gray-300"
			>
				{{ label }}
			</label>
		</div>

		<p v-if="message" class="mt-2 text-sm text-red-600">
			{{ message }}
		</p>

		<p v-if="hint" class="mt-2 text-xs text-gray-500 dark:text-gray-300">
			{{ hint }}
		</p>
	</fieldset>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const emit = defineEmits(['update:value']);
const props = defineProps({
	label: {
		type: String,
		required: true,
	},
	showLabel: {
		type: Boolean,
		default: true,
	},
	message: {
		type: String,
		default: null,
	},
	value: {
		type: String,
		default: null,
	},
	showPlaceholder: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: null,
	},
	hint: {
		type: String,
		default: null,
	},
	autofocus: { type: Boolean, default: false },
	resizable: { type: Boolean, default: true },
	disabled: { type: Boolean, default: false },
	cols: { type: Number, default: null },
	rows: { type: Number, default: null },
});

const input = ref(null);
const id = computed(() => `${props.label.replace(/\s/g, '-')}-textarea`);

function handleChange(event) {
	emit('update:value', event.target.value);
}

onMounted(() => {
	if (props.autofocus) {
		input.value.focus();
	}
});
</script>

<style scoped></style>
