<template>
	<section class="mb-2 flex flex-col items-start md:flex-row-reverse">
		<header class="md:w-1/2">
			<slot name="header">
				<h3 class="text-md font-bold">{{ itemLabel }} Sortable List</h3>
			</slot>

			<div id="sortableListDescription">
				<slot name="description">
					This list can be sorted by dragging the handle icon
					<kbd><FAIcon icon="bars" /></kbd> on each {{ itemLabel }} item.

					<p class="mt-2">
						<b>To sort items with the keyboard:</b> tab-focus on the item's handle icon
						<kbd><FAIcon icon="bars" /></kbd>, then use the keyboard up
						<kbd><FAIcon icon="arrow-up" /></kbd>
						and down <kbd><FAIcon icon="arrow-down" /></kbd> arrows to change position.
						<span v-if="canAddItems">
							Additional items can be added using the button following the list.
						</span>
						<span v-if="canRemoveItems">
							Items can be removed via the <b>REMOVE</b> button on each item.</span
						>
					</p>
				</slot>
			</div>
		</header>
		<div class="flex flex-col md:mr-8 md:w-1/2">
			<Draggable
				ref="draggable"
				v-model="localList"
				:animation="100"
				tabindex="0"
				tag="ol"
				class="flex flex-col gap-y-4 pl-0"
				handle=".drag"
				@start="dragging = true"
				@end="handleDragEnd"
			>
				<li
					v-for="(listItem, index) in localList"
					:key="listItem"
					class="rounded-lg bg-gray-200 p-4 text-gray-800 dark:bg-gray-700 dark:text-gray-100"
				>
					<div
						:ref="el => (handles[listItem] = el)"
						role="link"
						tabindex="0"
						class="drag grabHandle flex grow-0 cursor-move flex-row gap-4"
						:class="editing === index ? 'items-start' : 'items-center'"
						aria-describedby="sortableListDescription"
						@keydown.down.prevent="moveItemDown({ index, listItem })"
						@keydown.up.prevent="moveItemUp({ index, listItem })"
					>
						<FAIcon class="text-2xl text-gray-700 dark:text-gray-100" icon="bars" />

						<template v-if="itemType === 'textInput'">
							<div v-if="editing === index || listItem === ''" class="w-full">
								<ComplianceChecker
									:value="listItem"
									class="mr-2 w-full"
									:show-menu="false"
									confirm-changes
									:show-disclaimer="false"
									height="2rem"
									:max-character-count="20"
									:required="false"
									:name="`${itemLabel}: ${getItemPosition(index)}`"
									type="text"
									:hide-cancel="true"
									@keydown.enter.prevent="$emit('addItem')"
									@update:value="
										newValue => handleItemChanged({ value: newValue, index })
									"
								/>
							</div>
							<div v-else class="flex w-full min-w-0 flex-row items-center">
								<p
									class="mb-0 mt-0 min-w-0 max-w-full truncate text-2xl leading-none"
								>
									{{ listItem }}
								</p>
							</div>
						</template>
						<TeamMemberListItem
							v-if="itemType === 'teamMember'"
							:key="listItem.associate_id"
							:ref="listItem.associate_id"
							:index="index"
							:value="listItem"
							v-bind="getListItemContext(listItem)"
						/>

						<div class="flex flex-col gap-y-2">
							<button
								v-if="editing === index || listItem === ''"
								type="button"
								class="item-cta bg-white text-blue-700"
								:aria-label="`Cancel Editing item ${getItemPosition(
									index
								)}: ${getItemNameByType(listItem)}`"
								@click="handleCancel(listItem)"
							>
								<FAIcon icon="xmark" class="mr-0.5" /> CANCEL EDIT
							</button>
							<button
								v-else-if="canEditItems"
								type="button"
								class="item-cta bg-white text-blue-700 dark:bg-blue-700 dark:text-white"
								:aria-label="`Edit item ${getItemPosition(
									index
								)}: ${getItemNameByType(listItem)}`"
								@click="editing = index"
							>
								<FAIcon icon="pencil" class="mr-0.5" /> EDIT
							</button>
							<button
								v-if="canRemoveItems"
								type="button"
								class="item-cta bg-white dark:bg-gray-700 dark:text-white"
								:aria-label="`Remove item ${getItemPosition(
									index
								)}: ${getItemNameByType(listItem)}`"
								@click="$emit('removeItem', listItem)"
							>
								<FAIcon icon="xmark" class="mr-0.5" /> REMOVE
							</button>
						</div>
					</div>
				</li>
			</Draggable>
			<button
				v-if="canAddItems && localList.length < maxItemCount"
				type="button"
				class="mt-4 flex h-full w-full items-center justify-center rounded-full bg-gray-700 px-3 py-2 font-bold text-white transition-colors hover:bg-gray-800 focus:bg-gray-800 focus:outline-none focus:ring focus:ring-gray-500/50 focus:ring-offset-2"
				@click.prevent="$emit('addItem')"
			>
				Add {{ itemLabel }}
				<FAIcon icon="plus" class="ml-2" />
			</button>
		</div>
		<footer class="mt-4">
			<slot name="footer" />
		</footer>
	</section>
</template>

<script setup>
import Draggable from 'vuedraggable';
import { ref, watchEffect, nextTick } from 'vue';
import ComplianceChecker from '@/components/MXEditor/ComplianceChecker';
import TeamMemberListItem from '@/components/MXEditor/team/TeamMemberListItem';

const emit = defineEmits(['update:value', 'addItem', 'removeItem']);

const props = defineProps({
	value: { type: [Array, Object], required: true },
	canAddItems: { type: Boolean, default: true },
	canEditItems: { type: Boolean, default: true },
	canRemoveItems: { type: Boolean, default: false },
	maxItemCount: { type: Number, default: 10 },
	listContext: { type: Object, default: () => ({}) },
	itemLabel: {
		type: String,
		required: true,
	},
	itemType: {
		type: String,
		default: 'text',
		validator: value => {
			const validTypes = ['teamMember', 'textInput'];
			const styles = [
				'color: green',
				'background: yellow',
				'font-size: 15px',
				'padding: 2px',
			].join(';');
			if (validTypes.includes(value)) {
				return true;
			} else {
				console.group('SortableList');

				console.error(`SortableList ERROR: invalid type: %c${value}`, styles);
				console.warn(
					`Valid SortableList types include:\n\n%c - ${validTypes.join('\n - ')}`,
					styles
				);
				console.groupEnd();
				return false;
			}
		},
	},
});
const localList = ref(props.value);
const dragging = ref(false);
const editing = ref(null);
const assistiveText = ref('');

const handles = ref({});
function getItemPosition(index) {
	return `${index + 1} of ${localList.value.length}`;
}

function handleItemChanged({ value, index }) {
	const tempArray = [...localList.value];
	tempArray[index] = value;
	localList.value = [...tempArray];
	editing.value = null;
	emit('update:value', [...localList.value]);
}

async function refocusDragHandle({ listItem }) {
	await nextTick();

	const target = handles.value[listItem];

	//  refocus the drag handle
	target?.focus();
}
function moveItemUp({ index, listItem }) {
	if (index === 0) {
		assistiveText.value = `${getItemNameByType(listItem)}, first item in list.`;
		return;
	}

	const listCopy = [...localList.value];

	listCopy.splice(index, 1);
	listCopy.splice(index - 1, 0, listItem);

	emit('update:value', listCopy);
	assistiveText.value = `${getItemNameByType(listItem)}. New position in list: ${getItemPosition(
		index
	)}`;
	refocusDragHandle({ index: index - 1 });
}
async function moveItemDown({ listItem, index }) {
	if (index === localList.value?.length - 1) {
		assistiveText.value = `${getItemNameByType(listItem)}, last item in list.`;
		return;
	}
	const listCopy = [...localList.value];

	listCopy.splice(index, 1);

	listCopy.splice(index + 1, 0, listItem);

	refocusDragHandle({ listItem });

	emit('update:value', listCopy);
	assistiveText.value = `${getItemNameByType(listItem)}. New position in list: ${getItemPosition(
		index
	)}`;
}
function handleDragEnd() {
	dragging.value = false;

	emit('update:value', [...localList.value]);
}
function getItemNameByType(listItem) {
	switch (props.itemType) {
		case 'teamMember':
			return props.listContext[listItem]?.name;
		case 'textInput':
		default:
			return listItem;
	}
}
function handleCancel(listItem) {
	if (listItem.trim() === '') {
		emit('removeItem', listItem);
	} else {
		editing.value = null;
	}
}
function getListItemContext(listItem) {
	return { ...props.listContext[listItem], associate_id: listItem } ?? {};
}

watchEffect(() => {
	if (props.itemType === 'teamMember') {
		localList.value = Object.values(props.value);
	} else {
		localList.value = props.value;
	}
});
</script>

<style>
.grabHandle {
	@apply flex grow-0 items-center;
	@apply focus:outline-none focus:ring focus:ring-2 focus:ring-orange;
}

.item-cta {
	@apply shrink-0 whitespace-nowrap rounded-md bg-white p-0.5 px-1 text-xs  hover:text-orange-700 focus:outline-none focus:ring focus:ring-orange/50;
}
</style>
