<template>
	<div>
		<h5 class="text-xl font-bold">Suggested Photo Ideas:</h5>
		<ul class="list-disc">
			<li v-for="suggestion in suggestions" :key="suggestion">{{ suggestion }}</li>
		</ul>
	</div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
	overrideType: {
		type: String,
		validator: prop => {
			const valid = ['agent_avatar', 'staff_avatar', 'office_banner'].includes(prop);
			if (!valid) {
				console.error(
					`${prop} is not a valid image type. Valid types include 'agent_avatar', 'staff_avatar', and 'office_banner'`
				);
			}
			return valid;
		},
	},
});
const suggestions = computed(() => {
	switch (props.overrideType) {
		case 'office_banner':
			return [
				`The outside of your office can let viewers get a feel for where you're located.`,
				`The inside of your office is good if you have a unique layout, furniture or features.`,
				`An approved car wrap image can be used as a banner photo.`,
				`Landscape imagery depicting the local geography or seasons`,
				`Agent and/or agent team members at a community or sponsored event, however don't include people who are not the Agent, agent team members, or the Agent's immediate family members*`,
			];
		default:
			return false;
	}
});
</script>
