<template>
	<div class="pb-3">
		<div class="mx-auto mb-12 max-w-7xl px-4 sm:px-6 lg:px-8">
			<p class="text-center text-2xl text-gray-800">
				Choose an affordable domain plan for
				<span class="font-semibold text-zinc-900">{{ domainName }}</span> packed with the
				best features to make your new domain shine.
			</p>
			<ProgressBar
				v-if="progress.domains !== 100"
				:text="`Loading your current domain info (${progress.domains}%)`"
				:value="progress.domains"
				class="text-3xl"
			/>
			<div v-if="isIntern" class="flex space-x-4 rounded-md border-x-2 border-yellow-600 p-4">
				<FAIcon
					icon="fa-triangle-exclamation"
					class="h-12 w-12 shrink-0 text-yellow-600"
					aria-hidden="true"
				/>
				<div class="space-y-3 text-sm">
					<p class="text-base font-bold">Note for Agent Interns:</p>
					<p>{{ redirectOnlyForAgentInternText }}</p>
					<p>
						Select the <strong>Proxied SF Microsite</strong> option for now and if you
						choose, upgrade to M1 or M2 when you become an Agent. Call us with any
						questions: 1-877-828-3131
					</p>
				</div>
			</div>

			<div class="mt-18 lg:grid lg:grid-cols-2 lg:gap-x-6 xl:grid-cols-4">
				<div
					v-for="[key, v] in activeProductTypes"
					:key="key"
					class="relative mt-6 flex flex-col rounded-2xl border bg-white p-4"
					:class="[
						productType === key
							? 'border-sf-blue shadow-lg'
							: 'border-gray-200 shadow-sm',
					]"
				>
					<div class="flex-1">
						<h3
							class="mt-6 text-3xl font-semibold"
							:class="[productType === key ? 'text-gray-900' : 'text-gray-500']"
						>
							{{ v.titleShort }}
						</h3>

						<div class="my-4">
							<p
								v-if="v.highlight"
								class="absolute top-0 -translate-y-1/2 transform rounded-full border px-4 py-1.5 text-sm font-semibold"
								:class="[
									productType === key
										? 'bg-sf-blue text-white'
										: 'border-gray-200 bg-white text-sf-blue',
								]"
							>
								{{ v.highlight }}
							</p>
							<p
								class="mt-4 flex items-baseline"
								:class="[productType === key ? 'text-gray-900' : 'text-gray-500']"
							>
								<span class="text-5xl font-bold tracking-tight">
									${{ v.price }}
								</span>
								<span class="ml-1 text-xl font-semibold">/month</span>
							</p>
							<p class="mt-6 text-gray-500">{{ v.description }}</p>

							<!-- Feature list -->
							<ul class="ml-0 mt-6 space-y-4 pl-2">
								<li v-for="feature in v.features" :key="feature" class="flex">
									<FAIcon
										icon="fa-thin fa-circle-check"
										class="h-5 w-5 shrink-0 text-green-600"
										aria-hidden="true"
									/>
									<span class="ml-3 text-sm text-gray-800" v-html="feature" />
								</li>
							</ul>
						</div>
					</div>
					<div
						v-if="
							v.allowIntern &&
							isIntern &&
							(key === 'sf_microsite' || hasParkedTargets)
						"
						class="border border-x-transparent border-y-green-400 bg-green-50 py-2"
					>
						<div class="flex gap-x-2">
							<FAIcon
								icon="fa-thin fa-circle-arrow-right"
								class="h-6 w-6 shrink-0 text-green-600"
								aria-hidden="true"
							/>
							<span>
								<strong>As an agent intern</strong>, this is a recommended option
								until you're appointed
							</span>
						</div>
					</div>

					<div
						v-if="!v.allowIntern && isIntern"
						class="border border-x-transparent border-y-yellow-400 bg-yellow-50 py-2"
					>
						<div class="flex gap-x-2">
							<FAIcon
								icon="fa-thin fa-circle-exclamation"
								class="h-6 w-6 shrink-0 text-yellow-600"
								aria-hidden="true"
							/>
							<span>
								<strong>We do not recommend this option</strong> for agent interns
								until after you appoint
							</span>
						</div>
					</div>

					<div v-if="productType === key" class="">
						<div class="mt-4 flex gap-x-2">
							<FAIcon
								aria-hidden="true"
								class="h-8 w-8 shrink-0 text-green-600"
								icon="fa-circle-check"
							/>
							<span class="text-xl"> This is your currently selected plan </span>
						</div>
					</div>

					<div
						v-else-if="v.needsParkedTarget && !hasParkedTargets"
						class="mt-4 text-sm text-slate-500"
					>
						This option is not available until you to have at least one M2, M1, or
						Proxied SF Microsite to redirect this domain to.
					</div>
					<BaseButton
						v-else
						:disabled="isIntern && !v.allowIntern"
						class="mt-4 block py-3"
						rounded="md"
						@click.prevent="updateProductType(key)"
					>
						Select this plan!
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { computed, onMounted, inject } from 'vue';
import { storeToRefs } from 'pinia';

import { redirectOnlyForAgentInternText, productTypes } from '@/constants';

import useAgentStore from '@/stores/agent/agent';
import useDomainsStore from '@/stores/agent/agent-domains';

import BaseButton from '@/components/ui/BaseButton.vue';
import { isValidProductType } from '@/utils';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const agentStore = useAgentStore();
const domainsStore = useDomainsStore();

const { isIntern } = storeToRefs(agentStore);
const { parkedTargets, progress } = storeToRefs(domainsStore);

const { domainName } = inject('domainName');
const { productType, updateProductType } = inject('productType');

const activeProductTypes = computed(() =>
	Object.entries(productTypes).filter(([, v]) => !v.retired)
);

const hasParkedTargets = computed(() => parkedTargets.value?.length > 0 ?? false);
onMounted(async () => {
	await domainsStore.ensureDomains();

	if (!isValidProductType(productType.value)) {
		updateProductType('m2');
	}
});
</script>
<style scoped></style>
