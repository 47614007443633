<template>
	<ValidationObserver ref="observer" v-slot="{ valid, handleSubmit }" :disabled="disabled" slim>
		<form class="mb-4 mt-4 w-full" @submit.prevent="handleSubmit(updateRedirect)">
			<RedirectLayout :icon1="icon1" icon2="share" :loading="loading">
				<template #column1>
					<ValidationProvider
						ref="redirectValidator"
						v-slot="{ errors }"
						:rules="{
							required: true,
							redirect: {
								list: emailList,
								original: redirect,
								domain_name,
							},
						}"
						name="Redirect"
						class="flex"
					>
						<TextFieldInput
							ref="redirectInput"
							:value.sync="currentRedirect"
							:autofocus="!isNew"
							:disabled="disabled"
							:custom-i-d="`${domain_name} Email address`"
							label="Name or Prefix"
							:placeholder="agent.first_name.split(' ')[0].toLowerCase()"
							@keydown="preventInvalidInput"
							@keydown.esc="$emit('cancel')"
						>
							<template v-if="errors[0]" #message>
								<span>{{ errors[0] }}</span>
							</template>
						</TextFieldInput>
						<span
							class="border-l-solid ml-2 mt-3 h-full border-l-2 border-l-gray-400 pl-2 text-sm text-gray-600"
						>
							@{{ domain_name }}
						</span>
					</ValidationProvider>
				</template>
				<template #column2>
					<ValidationProvider
						v-slot="{ errors }"
						ref="destinationValidator"
						:rules="{
							required: true,
						}"
						name="Destination Address"
						tag="div"
					>
						<BaseSelect
							id="destinationAddress"
							show-label
							outlined
							:value.sync="currentDestination"
							:disabled="disabled"
							label="Destination Address"
							:items="destinationOptions"
							@keydown.esc="$emit('cancel')"
						/>
						<span v-if="errors[0]">{{ errors[0] }}</span>
					</ValidationProvider>
				</template>
				<template #actions>
					<BaseButton v-if="!isNew" :disabled="disabled" text @click="$emit('cancel')">
						Cancel
					</BaseButton>

					<BaseButton
						key="save"
						class="ml-1"
						type="submit"
						:disabled="!valid || disabled || !hasChanges"
						color="primary"
					>
						{{ isNew ? 'Add' : 'Save' }}
					</BaseButton>
				</template>
			</RedirectLayout>
		</form>
	</ValidationObserver>
</template>
<script setup>
import RedirectLayout from '@/components/emails/RedirectLayout';
import { computed, ref, watch } from 'vue';
import { extend } from 'vee-validate';
import { parseOneAddress } from 'email-addresses';

import useAgentStore from '@/stores/agent/agent';
import { storeToRefs } from 'pinia';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

extend('redirect', {
	params: ['list', 'original'],
	validate: (value, params) => {
		const { list, original, domain_name } = params;
		// no changes, just return
		if (original === value) {
			return true;
		}
		const existingMatch = list.find(email => {
			return value === email.redirect;
		});
		if (existingMatch) {
			return 'Looks like that redirect already exists';
		}
		return !!parseOneAddress(`${value}@${domain_name}`);
	},
});
const emit = defineEmits('change');
const props = defineProps({
	redirect: { type: String },
	destination_address: { type: String, default: null },
	domain_name: { type: String, required: true },
	isNew: { type: Boolean },
	disabled: { type: Boolean },
	loading: { type: Boolean },
	pk: { type: Number },
	emailList: { type: Array, required: true },
	destinationOptions: { type: Array, required: true },
});

const agentStore = useAgentStore();

const { agent } = storeToRefs(agentStore);

const currentRedirect = ref(props.redirect);
const currentDestination = ref(props.destination_address);
const observer = ref(null);
const hasChanges = computed(
	() =>
		props.redirect !== currentRedirect.value ||
		props.destination_address !== currentDestination.value
);
const icon1 = computed(() => {
	if (props.loading) {
		return 'refresh';
	}
	return props.isNew ? 'plus' : 'envelope';
});
function updateRedirect() {
	emit('change', {
		redirect: currentRedirect.value,
		destination_address: currentDestination.value,
		original: props.redirect,
		pk: props.pk,
	});
}
function preventInvalidInput(e) {
	if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]/.test(e.key)) {
		e.preventDefault();
	}
}
watch(
	() => props,
	(old, newVal) => {
		currentRedirect.value = (newVal?.redirect || old.redirect) ?? ``;
		currentDestination.value = (newVal?.destination_address || old.destination_address) ?? ``;
		observer.value?.reset();
	},
	{ immediate: true, deep: true }
);
</script>
