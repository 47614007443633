<template>
	<div class="rounded-lg border-2 border-gray-300 bg-sf-charcoal-100">
		<div class="flex flex-col justify-between gap-4 p-4 md:flex-row md:items-center">
			<div class="flex items-center gap-4">
				<p class="text-xl font-bold">Comp Deduct</p>
				<div
					v-if="enabled"
					class="flex grow-0 items-center gap-1 rounded-full border-2 border-green-800 bg-green-500/20 px-2 py-0.5 text-green-800"
				>
					<span class="text-sm font-bold">Selected</span>
					<FAIcon icon="fa-circle-check" size="xs" />
				</div>
			</div>
			<div class="flex items-center justify-end gap-2">
				<BaseButton v-if="!enabled" color="green" :loading="saving" @click="onUse">
					Use Comp Deduct
				</BaseButton>
				<BaseButton color="gray" text @click="open = !open">
					Details
					<template #append>
						<FAIcon
							icon="chevron-down"
							:class="open ? 'rotate-180' : 'rotate-0 pb-0.5'"
							class="transition-all duration-300"
						/>
					</template>
				</BaseButton>
			</div>
		</div>
		<BaseExpand>
			<div v-if="open" class="px-4 pb-4">
				<dt class="text-xl font-bold text-gray-600">What is Comp Deduct?</dt>
				<dd>
					Comp Deduct (AKA "Compensation Deduction" or "Payroll Deduction") is a system
					whereby agents can purchase goods and services from vendors in the SF ecosystem
					and have the cost of those goods and services deducted from their paycheck,
					instead of writing a check or having to charge those costs to a credit card. For
					many agents, this method of billing is more convenient for tracking expenses and
					tax records. MySFDomain has received approval from State Farm to begin offering
					agents the option of paying for their online services via Comp Deduct. We're
					pleased to now offer this as an option.
				</dd>
				<dt class="text-xl font-bold text-gray-600">Who is Comp Deduct for?</dt>
				<dd>Comp Deduct is available to be used by all State Farm agents.</dd>
				<dt class="text-xl font-bold text-gray-600">How does Comp Deduct work?</dt>
				<dd>
					Once a month, on your billing day, we cut a new invoice that includes the
					charges for all of the services for which you are currently signed up. Comp
					Deduct only processes once a month, and only on the 28th day of the month. The
					charges show up on your first paycheck of the month, which is closest to the
					15th.
				</dd>
				<div class="text-xl font-bold text-gray-600">
					How will MySFDomain charges appear on my Compensation statement?
				</div>
				<dd>
					All charges related to your domain and other online services purchased through
					MySFDomain will appear on your statement as "MYSFDOMAIN SERVICES" and will
					include the total dollar amount for all services with us for the previous month
					({{ cycleStartDay }} of last month through the {{ cycleEndDay }} of this month).
					All services which we bill can be seen at any time on your
					<AppLink :to="{ name: 'billing' }">Billing History</AppLink>
					page.
				</dd>
				<dt class="text-xl font-bold text-gray-600">
					I canceled service ABC with you, why is it still on my statement?
				</dt>
				<dd>
					Since Comp Deduct is billed in arrears, and because of the difference between
					your MySFDomain billing date and the Comp Deduct billing date, it's possible
					that you will see charges on your Comp Deduct statement up to 3 months after you
					have cancelled services with us. We DO NOT continue creating new charges after
					you cancel a service with us. However, we DO continue to collect charges for
					services rendered. If you have any questions or concerns about your statement,
					please reach out to our helpful support staff at
					<a href="mailto:support@mysfdomain.com">support@mysfdomain.com</a>
					.
				</dd>
			</div>
		</BaseExpand>
	</div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { set, format, startOfDay, sub } from 'date-fns';

import useAgentStore from '@/stores/agent/agent';
import usePaymentStore from '@/stores/agent/agent-payment';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseExpand from '@/components/ui/BaseExpand.vue';
import AppLink from '@/components/ui/AppLink.vue';

defineProps({
	enabled: Boolean,
});

const saving = ref(false);
const open = ref(false);

const agentStore = useAgentStore();
const paymentStore = usePaymentStore();
const { agent } = storeToRefs(agentStore);

const cycleStartDay = computed(() => {
	const offset = new Date() > agent.value.billing_day ? 1 : 0;
	return format(
		sub(set(startOfDay(new Date()), { date: agent.value.billing_day }), {
			months: 1 + offset,
		}),
		'do'
	);
});
const cycleEndDay = computed(() => {
	const offset = new Date() > agent.value.billing_day ? 1 : 0;

	return format(
		sub(set(startOfDay(new Date()), { date: agent.value.billing_day }), {
			months: offset,
			day: 1,
		}),
		'do'
	);
});

async function onUse() {
	if (saving.value) {
		return;
	}
	saving.value = true;
	await paymentStore.useCompDeduct();
	saving.value = false;
}
</script>
<style scoped>
.card-card {
	background-color: rgb(232, 232, 232);
}
</style>
