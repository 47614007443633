<template>
	<li class="flex flex-col justify-between">
		<div class="relative">
			<button
				class="text-md z-10 flex w-full grow items-center rounded-lg bg-gray-700 px-3 py-2 outline-none transition hover:bg-gray-600 focus:ring-2 focus:ring-orange-500/70"
				:class="routeMatched ? 'border-b-orange' : 'border-b-transparent'"
				:disabled="routeMatched"
				:aria-expanded="`${listIsOpen}`"
				:aria-controls="`${unique_key}-sidebar-page`"
				@click="listIsOpen = !listIsOpen"
			>
				<span class="flex grow items-center justify-items-stretch gap-x-1">
					<FAIcon v-if="icon" fixed-width size="lg" :icon="icon" class="text-white" />
					<span
						class="-mb-0.5 ml-2 grow whitespace-nowrap text-left text-lg font-semibold uppercase tracking-widest"
						:class="{ 'pr-12': componentKeys.includes('route-disable') }"
					>
						{{ editor.label || 'LABEL UNDEFINED' }} Page
					</span>
				</span>

				<span class="flex items-center justify-end">
					<FAIcon
						v-if="componentList.length"
						fixed-width
						size="lg"
						icon="angle-down"
						class="ml-2 text-white duration-200"
						:class="{ 'rotate-180': listIsOpen }"
					/>
				</span>
			</button>
			<RouteToggle
				v-if="componentKeys.includes('route-disable')"
				:unique_key="unique_key"
				:disabled="disabled"
				:a11y-label="`${editor.label} route is ${disabled ? 'disabled' : 'enabled'}.`"
				:sub-route-keys="[]"
				class="absolute bottom-0 right-11 top-0 my-auto"
			/>
		</div>

		<BaseExpand>
			<ul
				v-if="listIsOpen"
				:id="`${unique_key}-sidebar-page`"
				class="flex flex-col gap-2 !pl-2"
			>
				<template v-if="editor.editor_type === 'page'">
					<SidebarLink
						v-for="component in componentList"
						:key="component.path"
						indent="md"
						:label="component.label"
						:icon="component.icon"
						:disabled="editorStore.initializing"
						:exact="false"
						:to="{
							name: 'editor',
							params: { page: unique_key, editor: component.path },
						}"
						class="first:mt-2"
					/>
				</template>
			</ul>
		</BaseExpand>
	</li>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router/composables';

import useEditorStore from '@/stores/editor';

import { EditorViews } from '@/views/MXEditor/views';

import SidebarLink from '@/components/navigation/SidebarLink';
import RouteToggle from '@/components/navigation/RouteToggle';
import BaseExpand from '@/components/ui/BaseExpand';
const route = useRoute();

const props = defineProps({
	icon: { type: String, default: undefined },
	unique_key: { type: String, default: undefined },
	disabled: { type: Boolean, default: false },
	route_class: { type: String, default: undefined },
	context: { type: [Object, Array], required: true },
	editor: { type: Object, required: true },
	domainName: { type: String, default: undefined },
	listOpenDefault: { type: Boolean, default: false },
});

const listIsOpen = ref(props.listOpenDefault);

const editorStore = useEditorStore();
const componentKeys = computed(() => props.editor.components?.map(c => c.key) || []);

const componentList = computed(() => {
	return props.editor.components
		?.reduce((a, { key, ...rest }) => {
			const ev = EditorViews.find(({ path }) => path === key);

			// only grab components that have a match in the EditorViews
			if (ev) {
				a.push({ ...ev, ...rest });
			}
			return a;
		}, [])
		.sort((a, b) => a.label.localeCompare(b.label));
});
const routeMatched = computed(
	() => route.params.page !== undefined && route.params.page === props.editor.path
);
</script>

<style>
.fade-enter-active {
	@apply duration-200 ease-out;
}
.fade-leave-active {
	@apply opacity-0 duration-100 ease-in;
}
.fade-enter {
	@apply opacity-0;
}
</style>
